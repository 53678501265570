import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Box,
  Button,
  IconButton,
  Typography,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Divider,
  TableRow,
  Paper,
  MenuItem,
  Select,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';
import { FaArrowLeft, FaTrash, FaInfoCircle } from 'react-icons/fa';
import SchemaContext from '../SchemaContext';

export default function AttributeDetails() {
  const { id } = useParams();
  const { schema, setSchema } = useContext(SchemaContext);
  const { accessToken, orgHelper } = useAuthInfo();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isSystemAttribute, setIsSystemAttribute] = useState(false);
  const [attributeData, setAttributeData] = useState({});
  const [nonEmptyCoverage, setNonEmptyCoverage] = useState(0);
  const [consistentCoverage, setConsistentCoverage] = useState(0);
  const [editableFields, setEditableFields] = useState({
    name: '',
    enrich_type: '',
    allowed_values: [],
    context: '',
  });
  const [newAllowedValue, setNewAllowedValue] = useState('');
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const keyMapping = {
    allowed_values: 'Allowed Values',
    catalog_id: 'Catalog ID',
    created_at: 'Created At',
    enrich_type: 'Enrich Type',
    id: 'Attribute ID',
    name: 'Attribute Name',
    updated_at: 'Updated At',
    context: 'Definition',
  };

  useEffect(() => {
    getAttributeDetails();
  }, []);

  const getAttributeDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/catalog/get/${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setAttributeData(response.data.fields);
      setNonEmptyCoverage(response.data.coverage.nonEmpty || 0);
      setConsistentCoverage(response.data.coverage.consistent || 0);
      setEditableFields({
        name: response.data.fields.name || '',
        enrich_type: response.data.fields.enrich_type || '',
        allowed_values: response.data.fields.allowed_values || [],
        context: response.data.fields.context || '',
      });
      setIsSystemAttribute(response.data.is_system);
    } catch (error) {
      console.error('Error fetching attribute details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setEditableFields((prev) => ({ ...prev, [field]: value }));
  };

  const toTitleCase = (str) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const handleAddAllowedValue = () => {
    if (
      newAllowedValue.trim() !== '' &&
      !editableFields.allowed_values.includes(newAllowedValue.trim())
    ) {
      const transformedValue = toTitleCase(newAllowedValue.trim());
      setEditableFields((prev) => ({
        ...prev,
        allowed_values: [...prev.allowed_values, transformedValue.trim()],
      }));
      setNewAllowedValue('');
    }
  };

  const handleDeleteAllowedValue = (value) => {
    setEditableFields((prev) => ({
      ...prev,
      allowed_values: prev.allowed_values.filter((val) => val !== value),
    }));
  };

  const handleSave = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/catalog/update/${id}`,
        {
          attributeName: editableFields.name,
          attributeType: editableFields.enrich_type,
          allowedValues: editableFields.allowed_values,
          context: editableFields.context,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setSchema({
        ...schema,
        [id]: {
          name: editableFields.name,
          enrichType: editableFields.enrich_type,
          allowedValues: editableFields.allowed_values,
          definition: editableFields.context,
        },
      });
      navigate('/attributes');
    } catch (error) {
      console.error('Error saving attribute details:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/catalog/delete/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setSchema((prevSchema) => {
        const updatedSchema = { ...prevSchema };
        delete updatedSchema[id];
        return updatedSchema;
      });
      navigate('/attributes');
    } catch (error) {
      console.error('Error deleting attribute:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const getColorForPercentage = (percentage) => {
    if (percentage < 0.3) return 'error';
    if (percentage < 0.7) return 'warning';
    return 'success';
  };

  const CoverageIndicator = ({ label, value }) => (
    <Box mb={2}>
      <Typography variant="body2" gutterBottom>
        {label}: {(value * 100).toFixed(1)}%
      </Typography>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress
            variant="determinate"
            value={value * 100}
            color={getColorForPercentage(value)}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {(value * 100).toFixed(0)}%
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const renderTableCell = (key, value) => {
    switch (key) {
      case 'name':
        return !isSystemAttribute ? (
          <TextField
            value={editableFields.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            variant="outlined"
            fullWidth
          />
        ) : (
          <Typography>{value}</Typography>
        );
      case 'enrich_type':
        return (
          <Select
            value={editableFields.enrich_type}
            onChange={(e) => handleInputChange('enrich_type', e.target.value)}
            label="Enrich Type"
            fullWidth
          >
            <MenuItem value="GENERATE">Generate</MenuItem>
            <MenuItem value="CLASSIFY">Classify</MenuItem>
            <MenuItem value="EXTRACT">Extract</MenuItem>
            <MenuItem value="NONE">-</MenuItem>
          </Select>
        );
      case 'context':
        return (
          <TextField
            value={editableFields.context}
            onChange={(e) => handleInputChange('context', e.target.value)}
            variant="outlined"
            fullWidth
            multiline
            helperText="Provide additional context or specify your desired output format, e.g. 'always round up to the nearest dollar'."
          />
        );
      case 'allowed_values':
        return (
          <Box>
            <Box display="flex" alignItems="center">
              <TextField
                value={newAllowedValue}
                onChange={(e) => setNewAllowedValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddAllowedValue();
                  }
                }}
                variant="outlined"
                fullWidth
                placeholder="Add new value"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAllowedValue}
                style={{ marginLeft: '8px' }}
                disabled={!newAllowedValue}
              >
                Add
              </Button>
            </Box>
            <Box mt={2}>
              {editableFields.allowed_values.map((val, index) => (
                <Chip
                  key={index}
                  label={val}
                  onDelete={() => handleDeleteAllowedValue(val)}
                  style={{ margin: '2px' }}
                />
              ))}
            </Box>
          </Box>
        );
      default:
        return <Typography>{value}</Typography>;
    }
  };

  return (
    <Box>
      <Box mb={3} display="flex" alignItems="center">
        <IconButton
          onClick={() => navigate('/attributes')}
          aria-label="go back"
          size="large"
        >
          <FaArrowLeft />
        </IconButton>
        <Typography variant="h4">Attribute Details</Typography>
      </Box>
      <Box display="flex" mb={3}>
        {isSystemAttribute ? (
          <Tooltip
            title="This is a system attribute: edits are limited and it cannot be deleted."
            arrow
          >
            <Chip
              icon={<FaInfoCircle />}
              label="System Attribute"
              color="primary"
              variant="outlined"
            />
          </Tooltip>
        ) : (
          <Button
            onClick={() => setDeleteConfirmationOpen(true)}
            color="error"
            startIcon={<FaTrash />}
          >
            Delete Attribute
          </Button>
        )}
      </Box>
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this attribute? Data will be lost on
          all products with this attribute.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>No</Button>
          <Button onClick={handleDelete} color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Paper elevation={3} mt={3} mb={4} sx={{ p: 3, width: '100%' }}>
        <Typography variant="h6" gutterBottom>
          Quality Metrics
        </Typography>
        <CoverageIndicator label="Completeness" value={nonEmptyCoverage} />
        {editableFields.enrich_type === 'CLASSIFY' && (
          <CoverageIndicator
            label="Standardization"
            value={consistentCoverage}
          />
        )}
        <Divider />
        <TableContainer
          variant="outlined"
          style={{ maxWidth: '100%', marginBottom: '20px' }}
          mb={3}
        >
          <Table aria-label="attribute details table">
            <TableBody>
              {Object.entries(attributeData)
                .filter(
                  ([key]) => !['id', 'catalog_id', 'is_system'].includes(key)
                )
                .filter(
                  ([key, value]) =>
                    !(
                      key === 'allowed_values' &&
                      !['CLASSIFY', 'GENERATE'].includes(
                        editableFields.enrich_type
                      )
                    )
                )
                .sort(([a], [b]) => {
                  const order = [
                    'name',
                    'enrich_type',
                    'allowed_values',
                    'context',
                    'created_at',
                    'updated_at',
                  ];
                  return order.indexOf(a) - order.indexOf(b);
                })
                .map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{keyMapping[key] || key}</TableCell>
                    <TableCell>{renderTableCell(key, value)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box mt={3}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}
