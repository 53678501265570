import React from 'react';
import {
  FaInfoCircle,
  FaExternalLinkAlt,
  FaCheck,
  FaCopy,
  FaMagic,
} from 'react-icons/fa';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Link,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import PDPTooltip from '../../components/PDPTooltip';
import ReviewAmbiguousOutput from './ReviewAmbiguousOutput';

const AttributeField = ({
  attributeId,
  catalogAttributeData,
  productAttributeData,
  handleFieldChange,
  handleEnrichAttribute,
  isEnrichLoading,
}) => {
  const [copiedField, setCopiedField] = React.useState(false);
  const handleCopy = async (value) => {
    try {
      await navigator.clipboard.writeText(value);
      setCopiedField(true);
      setTimeout(() => setCopiedField(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const renderIcons = () => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {catalogAttributeData.name.toLowerCase() === 'url' ? (
        <IconButton
          color="primary"
          size="small"
          component={Link}
          href={productAttributeData.value}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaExternalLinkAlt size={18} />
        </IconButton>
      ) : catalogAttributeData.name === 'ShopifyID' ? (
        <IconButton
          color="primary"
          size="small"
          component={Link}
          href={
            'https://admin.shopify.com/products/' +
            productAttributeData.value.split('/').pop()
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaExternalLinkAlt size={18} />
        </IconButton>
      ) : (
        <IconButton
          color="primary"
          size="small"
          onClick={() => handleCopy(productAttributeData.value)}
        >
          {copiedField ? <FaCheck size={18} /> : <FaCopy size={18} />}
        </IconButton>
      )}
      {catalogAttributeData.enrichType !== 'NONE' && (
        <IconButton
          color="primary"
          size="small"
          onClick={() => handleEnrichAttribute()}
          disabled={isEnrichLoading}
        >
          {isEnrichLoading ? (
            <CircularProgress size={18} />
          ) : (
            <FaMagic size={18} />
          )}
        </IconButton>
      )}
    </Box>
  );

  return (
    <Grid item xs={12} key={attributeId}>
      {productAttributeData && (
        <Box>
          <Box display="flex" alignItems="center">
            {(catalogAttributeData.definition ||
              productAttributeData.explanation) && (
              <Tooltip
                title={
                  <PDPTooltip
                    attributeDefinition={catalogAttributeData.definition}
                    modelExplanation={productAttributeData.explanation}
                  />
                }
                arrow
              >
                <IconButton size="small">
                  <FaInfoCircle fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Box flexGrow={1}>
              {catalogAttributeData.enrichType === 'CLASSIFY' &&
              catalogAttributeData.name !== 'Category' &&
              catalogAttributeData.allowedValues ? (
                <Autocomplete
                  options={catalogAttributeData.allowedValues}
                  value={productAttributeData.value || null}
                  fullWidth
                  onChange={(event, newValue) => {
                    handleFieldChange(attributeId, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={attributeId}
                      label={catalogAttributeData.name}
                      margin="normal"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                            {renderIcons()}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              ) : (
                <TextField
                  disabled={
                    catalogAttributeData.name === 'ShopifyID' ||
                    catalogAttributeData.name === 'LowestPricedVariantID'
                  }
                  name={attributeId}
                  label={catalogAttributeData.name}
                  value={productAttributeData.value}
                  onChange={(e) =>
                    handleFieldChange(e.target.name, e.target.value)
                  }
                  fullWidth
                  margin="normal"
                  multiline
                  InputProps={{
                    endAdornment: renderIcons(),
                  }}
                />
              )}
            </Box>
          </Box>

          <ReviewAmbiguousOutput
            attributeId={attributeId}
            fieldName={catalogAttributeData.name}
            editedFieldData={productAttributeData}
            handleFieldChange={handleFieldChange}
          />
        </Box>
      )}
    </Grid>
  );
};

export default AttributeField;
