import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';
import { AgGridReact } from 'ag-grid-react';
import {
  Typography,
  Button,
  Paper,
  Skeleton,
  CircularProgress,
  Box,
} from '@mui/material';
import { FaCheckCircle, FaExclamationCircle, FaCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import GridLoadingSkeleton from '../components/GridLoadingSkeleton';

export default function ManageSources() {
  const navigate = useNavigate();
  const { isLoggedIn, accessToken, orgHelper } = useAuthInfo();
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch sources data from the backend
    const fetchSources = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/sources/get-all`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Authorization header
            },
          }
        );
        setSources(
          response.data.sources.map((source) => ({
            ...source,
            created_at: new Date(source.created_at),
          }))
        );
      } catch (error) {
        console.error('Error fetching sources:', error);
      } finally {
        setLoading(false);
      }
    };
    if (isLoggedIn) {
      fetchSources();
    }
  }, []);

  const StatusCellRenderer = (params) => {
    switch (params.value) {
      case 'RUNNING':
        return <CircularProgress size={20} />;
      case 'SUCCESS':
        return <FaCheckCircle style={{ color: 'green', fontSize: '20px' }} />;
      case 'ERROR':
        return (
          <FaExclamationCircle style={{ color: 'red', fontSize: '20px' }} />
        );
      case 'CREATED':
        return <FaCircle style={{ color: 'gray', fontSize: '16px' }} />;
      default:
        return params.value;
    }
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    maxWidth: 200,
  };

  const sourceColumnDefs = [
    { headerName: 'ID', field: 'id', hide: true },
    { headerName: 'Name', field: 'name', pinned: 'left' },
    { headerName: 'Type', field: 'type' },
    { headerName: '# Products', field: 'num_products' },
    { headerName: 'Schedule', field: 'schedule' },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: StatusCellRenderer,
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      headerName: 'Last Run At',
      field: 'last_run_at',
    },
  ];
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Sources
      </Typography>
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/sources/create');
          }}
        >
          + Create Source
        </Button>
      </Box>
      <Paper style={{ marginTop: '20px' }}>
        <div
          className="ag-theme-quartz"
          style={{ height: '400px', width: '100%' }}
        >
          {loading ? (
            <GridLoadingSkeleton />
          ) : (
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={sourceColumnDefs}
              rowData={sources}
              pagination={true}
              paginationPageSize={20}
              alwaysShowHorizontalScroll
              onRowClicked={(params) => {
                navigate(`/sources/${params.data.id}`, {
                  state: params.data,
                });
              }}
              onFirstDataRendered={(params) => {
                params.api.autoSizeAllColumns();
              }}
            ></AgGridReact>
          )}
        </div>
      </Paper>
    </>
  );
}
