import confetti from 'canvas-confetti';

export const triggerConfetti = () => {
  const count = 2;
  const defaults = {
    origin: { y: 0.7 },
    spread: 90,
    ticks: 200,
  };

  function fire(particleRatio, opts) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(200 * particleRatio),
      colors: [
        '#ff0000',
        '#00ff00',
        '#0000ff',
        '#ffff00',
        '#ff00ff',
        '#00ffff',
      ],
      shapes: ['square', 'circle'],
      scalar: 1.2,
    });
  }

  for (let i = 0; i < count; i++) {
    setTimeout(() => {
      fire(0.25, { spread: 26, startVelocity: 55 });
      fire(0.2, { spread: 60 });
      fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8 });
      fire(0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 });
      fire(0.1, { spread: 120, startVelocity: 45 });
    }, i * 200);
  }
};
