import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  FaLink,
  FaList,
  FaExclamationCircle,
  FaBarcode,
  FaShoppingCart,
} from 'react-icons/fa';
import { useAuthInfo } from '@propelauth/react';

const ScrapeOptions = ({ onImport }) => {
  const { accessToken, orgHelper } = useAuthInfo();
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [inputArray, setInputArray] = useState(['']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setInputValue('');
    setInputArray(['']);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleArrayInputChange = (index, value) => {
    const newArray = [...inputArray];
    newArray[index] = value;
    setInputArray(newArray);
  };

  const addArrayInput = () => {
    setInputArray([...inputArray, '']);
  };

  const handleScrape = async () => {
    const urlRegex =
      /(http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    const skuRegex = /^[^\s]+$/;

    const isValidUrl = (url) => url.match(urlRegex) !== null;
    const isValidSku = (sku) => sku.match(skuRegex) !== null;

    let isValid = true;

    if (selectedOption === 'singleURL' || selectedOption === 'listingURL') {
      isValid = isValidUrl(inputValue);
      if (!isValid) {
        setError('Please enter a valid URL!');
        return;
      }
    } else if (selectedOption === 'singleSKU') {
      isValid = isValidSku(inputValue);
      if (!isValid) {
        setError('Please enter a valid SKU!');
        return;
      }
    } else if (selectedOption === 'multipleSKUs') {
      isValid = inputArray.every((sku) => isValidSku(sku));
      if (!isValid) {
        setError('Please enter valid SKUs (non-empty values without spaces)!');
        return;
      }
    }

    try {
      setLoading(true);
      const data =
        selectedOption === 'multipleSKUs' || selectedOption === 'multipleURLs'
          ? { scrape_type: selectedOption, value: inputArray }
          : { scrape_type: selectedOption, value: inputValue };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/scrape/product-listings`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data.products.length === 0) {
        setError(
          'Could not scrape product(s) at the given URL! Please try a different URL or email ray@anglercloud.com for custom scraping support.'
        );
        return;
      }

      let domainName;
      if (selectedOption === 'singleURL' || selectedOption === 'listingURL') {
        const domainRegex =
          /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/im;
        const match = inputValue.match(domainRegex);
        domainName = match ? match[1] : 'New Source';
      } else if (selectedOption === 'singleSKU') {
        domainName = inputValue;
      } else if (selectedOption === 'multipleSKUs') {
        domainName = inputArray[0];
      }

      setError(null);
      onImport(response.data.products, domainName);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Import products from the web
      </Typography>
      <Grid container spacing={2}>
        {[
          {
            option: 'singleURL',
            label: 'Single Product Detail Page',
            icon: <FaLink />,
          },
          {
            option: 'listingURL',
            label: 'Product Listings Link',
            icon: <FaList />,
          },
          // TODO: Implement this
          {
            option: 'singleSKU',
            label: 'Single SKU',
            icon: <FaBarcode />,
          },
          // {
          //   option: 'multipleSKUs',
          //   label: 'Multiple SKUs',
          //   icon: <FaShoppingCart />,
          // },
        ].map(({ option, label, icon }) => (
          <Grid item xs={6} md={3} key={option}>
            <Card
              sx={{ cursor: 'pointer', textAlign: 'center' }}
              onClick={() => handleOptionClick(option)}
              raised={selectedOption === option}
            >
              <CardContent>
                <IconButton>{icon}</IconButton>
                <Typography variant="subtitle1">{label}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box mt={3}>
        {selectedOption === 'singleURL' || selectedOption === 'listingURL' ? (
          <TextField
            label="URL"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
          />
        ) : null}

        {selectedOption === 'singleSKU' ? (
          <TextField
            label="SKU"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
          />
        ) : null}

        {selectedOption === 'multipleSKUs' ? (
          <>
            {inputArray.map((value, index) => (
              <TextField
                key={index}
                label={`SKU ${index + 1}`}
                variant="outlined"
                fullWidth
                value={value}
                onChange={(e) => handleArrayInputChange(index, e.target.value)}
                sx={{ mb: 2 }}
              />
            ))}
            <Button onClick={addArrayInput}>Add another SKU</Button>
          </>
        ) : null}

        {selectedOption && (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleScrape}
              disabled={loading || inputValue.trim() === ''}
            >
              {loading ? <CircularProgress size={24} /> : 'Scrape'}
            </Button>
            {!loading && error && (
              <Box mt={2}>
                <Box display="flex" alignItems="center" color="error.main">
                  <FaExclamationCircle />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {error}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ScrapeOptions;
