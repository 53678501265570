import React, { useState, useCallback, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const AgGridProductSelect = ({
  products,
  selectedProducts,
  setSelectedProducts,
}) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(products);
  }, [products]);

  const dateFormatter = (params) => {
    return new Date(params.value).toLocaleString();
  };

  const [columnDefs] = useState([
    {
      headerName: '',
      field: 'id',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      width: 50,
      maxWidth: 50,
    },
    { headerName: 'Product Name', field: 'name', filter: true },
    {
      headerName: 'Created At',
      field: 'createdAt',
      filter: true,
      valueFormatter: dateFormatter,
    },
    {
      headerName: 'Updated At',
      field: 'updatedAt',
      filter: true,
      valueFormatter: dateFormatter,
    },
    {
      headerName: 'Source',
      field: 'source',
      filter: true,
    },
  ]);

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
    filter: true,
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    const selectedIds = selectedRows.map((row) => row.id);
    console.log('selectedIds', selectedIds.length);
    setSelectedProducts(selectedIds);
  }, [setSelectedProducts]);

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.forEachNode((node) => {
        node.setSelected(selectedProducts.includes(node.data.id));
      });
    }
  }, [selectedProducts]);

  return (
    <div className='ag-theme-quartz' style={{ height: 400, width: '100%' }}>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection='multiple'
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        rowData={rowData}
        suppressCellFocus={true}
      />
    </div>
  );
};

export default AgGridProductSelect;
