import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from '@mui/material';
import { FaSyncAlt } from 'react-icons/fa';

const VariantFormModal = ({
  open,
  onClose,
  onSave,
  variant,
  variantAttributes,
}) => {
  const [formData, setFormData] = useState({
    sku: '',
    variant_attributes: {},
  });

  useEffect(() => {
    if (variant) {
      setFormData({
        sku: variant.sku,
        variant_attributes: { ...variant.variant_attributes },
      });
    } else {
      setFormData({
        sku: '',
        variant_attributes: variantAttributes.reduce(
          (acc, attr) => ({ ...acc, [attr]: '' }),
          {}
        ),
      });
    }
  }, [variant, variantAttributes]);

  const handleChange = (field, value) => {
    if (field === 'sku') {
      setFormData({ ...formData, sku: value });
    } else {
      setFormData({
        ...formData,
        variant_attributes: { ...formData.variant_attributes, [field]: value },
      });
    }
  };

  const generateSKU = () => {
    const prefix = 'VAR';
    const timestamp = Date.now().toString().slice(-6);
    const random = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, '0');
    const newSKU = `${prefix}-${timestamp}-${random}`;
    setFormData({ ...formData, sku: newSKU });
  };

  const handleSubmit = async () => {
    await onSave(formData);
    setFormData({ sku: '', variant_attributes: {} });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{variant ? 'Edit Variant' : 'Create Variant'}</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" mb={2} gap={1}>
          <TextField
            label="SKU"
            value={formData.sku}
            onChange={(e) => handleChange('sku', e.target.value)}
            fullWidth
            margin="normal"
            required
            helperText={!formData.sku ? 'SKU is required' : ''}
          />
          <Button
            onClick={generateSKU}
            startIcon={<FaSyncAlt />}
            variant="outlined"
            sx={{ flexShrink: 0, whiteSpace: 'nowrap' }}
          >
            Generate
          </Button>
        </Box>
        {variantAttributes.map((attr) => (
          <TextField
            key={attr}
            fullWidth
            margin="normal"
            label={attr}
            value={formData.variant_attributes[attr] || ''}
            onChange={(e) => handleChange(attr, e.target.value)}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!formData.sku}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          {variant ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VariantFormModal;
