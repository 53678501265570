import { useEffect } from 'react';
const LandingRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://www.anglercloud.com';
  });

  return null;
};

export default LandingRedirect;
