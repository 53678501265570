import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Paper,
  CircularProgress,
  Grid,
  Chip,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

const CustomHeaderComponent = (props) => {
  const {
    displayName,
    column,
    schema,
    onMappingChange,
    initialMapping,
    isLoading,
    usedMappings,
  } = props;
  const [mappedTo, setMappedTo] = useState('');

  useEffect(() => {
    const colId = column.getColId();
    setMappedTo(initialMapping[colId] || '');
  }, [initialMapping, column]);

  const handleChange = (event) => {
    const selectedOption = event.target.value;
    setMappedTo(selectedOption);
    onMappingChange(column.getColId(), selectedOption);
  };

  return (
    <Box
      sx={{
        padding: '10px',
        borderBottom: '1px solid #ddd',
      }}
    >
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
        {displayName}
      </Typography>
      <FormControl fullWidth size="small">
        <Select
          value={mappedTo}
          onChange={handleChange}
          displayEmpty
          renderValue={
            isLoading
              ? () => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                    Loading...
                  </Box>
                )
              : undefined
          }
          disabled={isLoading || mappedTo === 'ShopifyID'}
        >
          <MenuItem value="">
            <em>Unmapped</em>
          </MenuItem>
          <MenuItem
            value="Image URL"
            disabled={
              usedMappings.includes('Image URL') && 'Image URL' !== mappedTo
            }
          >
            Image URL
          </MenuItem>
          {Object.keys(schema).map((key) => (
            <MenuItem
              key={schema[key].name}
              value={schema[key].name}
              disabled={
                usedMappings.includes(schema[key].name) &&
                schema[key].name !== mappedTo
              }
            >
              {schema[key].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const SingleProductMapping = ({
  product,
  schema,
  mapping,
  onMappingChange,
  isLoading,
  usedMappings,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Map To</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(product).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell
                sx={{
                  maxWidth: '200px', // Adjust this value as needed
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
              >
                {value}
              </TableCell>
              <TableCell>
                <FormControl fullWidth size="small">
                  <Select
                    value={mapping[key] || ''}
                    onChange={(e) => onMappingChange(key, e.target.value)}
                    displayEmpty
                    disabled={isLoading || mapping[key] === 'ShopifyID'}
                    renderValue={
                      isLoading
                        ? () => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <CircularProgress size={20} sx={{ mr: 1 }} />
                              Loading...
                            </Box>
                          )
                        : undefined
                    }
                  >
                    <MenuItem value="">
                      <em>Unmapped</em>
                    </MenuItem>
                    <MenuItem
                      value="Image URL"
                      disabled={
                        usedMappings.includes('Image URL') &&
                        'Image URL' !== mapping[key]
                      }
                    >
                      Image URL
                    </MenuItem>
                    {Object.keys(schema).map((schemaKey) => (
                      <MenuItem
                        key={schema[schemaKey].name}
                        value={schema[schemaKey].name}
                        disabled={
                          usedMappings.includes(schema[schemaKey].name) &&
                          schema[schemaKey].name !== mapping[key]
                        }
                      >
                        {schema[schemaKey].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MappingSummary = ({ mapping, csvData }) => {
  const totalFields =
    csvData && csvData.length > 0
      ? [...new Set(csvData.flatMap(Object.keys))].length
      : 0;
  const mappedFields = Object.keys(mapping).filter(
    (key) => mapping[key]
  ).length;
  const unmappedFields = totalFields - mappedFields;

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Mapping Summary
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Total Fields</Typography>
          <Chip label={totalFields} color="default" />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Mapped Fields</Typography>
          <Tooltip
            title={Object.entries(mapping)
              .filter(([_, value]) => value)
              .map(([key, value]) => `${key} → ${value}`)
              .join(', ')}
          >
            <Chip label={mappedFields} color="success" />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Unmapped Fields</Typography>
          <Tooltip
            title={Object.keys(csvData[0] || {})
              .filter((key) => !mapping[key] || mapping[key] === '')
              .join(', ')}
          >
            <Chip
              label={unmappedFields}
              color={unmappedFields > 0 ? 'warning' : 'success'}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
};

const DataMapping = ({
  csvData,
  schema,
  initialMapping,
  onMappingComplete,
  loading,
  sourceType,
}) => {
  const [mapping, setMapping] = useState(initialMapping || {});
  const [usedMappings, setUsedMappings] = useState([]);
  useEffect(() => {
    setMapping(initialMapping || {});
    setUsedMappings(Object.values(initialMapping || []));
  }, [initialMapping]);

  const handleMappingChange = useCallback((columnName, schemaKey) => {
    setMapping((prevMapping) => {
      const newMapping = { ...prevMapping };

      // Remove the old mapping if it exists
      if (newMapping[columnName]) {
        setUsedMappings((prev) =>
          prev.filter((item) => item !== newMapping[columnName])
        );
      }

      // Add the new mapping
      newMapping[columnName] = schemaKey;

      if (schemaKey) {
        setUsedMappings((prev) => [...prev, schemaKey]);
      }

      return newMapping;
    });
  }, []);

  const zyteKeysToRemove = [
    // 'mainImage_url',
    'images',
    'metadata_dateDownloaded',
    'metadata_probability',
    'aggregateRating_reviewCount',
    'aggregateRating_ratingValue',
  ];

  const columnDefs = useMemo(() => {
    if (csvData && csvData.length > 0) {
      return [...new Set(csvData.flatMap(Object.keys))]
        .filter(
          (key) => !(sourceType === 'SCRAPE' && zyteKeysToRemove.includes(key))
        )
        .map((key) => ({
          headerName: key,
          field: key,
          pinned: key.toLowerCase() === 'name' ? 'left' : null,
          headerComponent: 'customHeaderComponent',
          headerComponentParams: {
            schema: schema,
            onMappingChange: handleMappingChange,
            initialMapping: mapping,
            isLoading: loading,
            usedMappings: usedMappings,
          },
          // minWidth: 200,
          maxWidth: 300,
          authSize: true,
          resizable: true,
        }));
    }
    return [];
  }, [csvData, schema, handleMappingChange, mapping, loading, usedMappings]);

  const rowData = useMemo(() => {
    if (sourceType === 'SCRAPE') {
      return csvData.map((obj) =>
        Object.fromEntries(
          Object.entries(obj).filter(([key]) => !zyteKeysToRemove.includes(key))
        )
      );
    }
    return csvData;
  }, [csvData, sourceType]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      autoSize: true, // Apply autoSize to all columns by default
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();

    // Adjust columns after a short delay to ensure content is rendered
    setTimeout(() => {
      gridApi.autoSizeAllColumns();
    }, 100);

    // Resize on window resize
    const handleResize = () => {
      gridApi.sizeColumnsToFit();
      gridApi.autoSizeAllColumns();
    };
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = () => {
    onMappingComplete(mapping);
  };
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>
        Explicitly map the fields you'd like to use here. We'll also save all of
        the data to help with enrichment later.
      </Typography>
      <MappingSummary mapping={mapping} csvData={csvData} />
      <Paper elevation={3} sx={{ flex: 1, mb: 2, overflow: 'hidden' }}>
        {csvData.length === 1 ? (
          <SingleProductMapping
            product={rowData[0]}
            schema={schema}
            mapping={mapping}
            onMappingChange={handleMappingChange}
            isLoading={loading}
            usedMappings={usedMappings}
          />
        ) : (
          <Box
            className="ag-theme-material"
            sx={{ height: '600px', width: '100%' }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={10}
              components={{
                customHeaderComponent: CustomHeaderComponent,
              }}
              headerHeight={100}
            />
          </Box>
        )}
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          Confirm Mapping
        </Button>
      </Box>
    </Box>
  );
};

export default DataMapping;
