import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const PercentageBarCellRenderer = ({ value, data }) => {
  const percentage = Math.round(value * 100);
  // const enrichType = data.type;

  const getColor = (percent) => {
    if (percent >= 95) return '#4caf50'; // Green
    if (percent >= 85) return '#8bc34a'; // Light Green
    if (percent >= 75) return '#ffeb3b'; // Yellow
    if (percent >= 65) return '#ff9800'; // Orange
    return '#f44336'; // Red
  };

  // const color = getColor(percentage, enrichType);
  const color = getColor(percentage);

  // Get the label based on enrichType
  // const getLabel = (type) => {
  //   return type === 'Infer (Closed)' ? 'Standardization' : 'Completeness';
  // };

  // const label = getLabel(enrichType);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      {/* <Typography variant="caption" color="textSecondary">
        {label}
      </Typography> */}
      <Box display="flex" alignItems="center" width="100%">
        <Box width="100%" mr={1}>
          <LinearProgress
            variant="determinate"
            value={percentage}
            sx={{
              height: 12,
              borderRadius: 5,
              backgroundColor: '#e0e0e0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: color,
                borderRadius: 5,
              },
            }}
          />
        </Box>
        <Box minWidth={35}>
          <Typography
            variant="body2"
            color="textSecondary"
          >{`${percentage}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PercentageBarCellRenderer;
