import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import PrettifiedJSONViewer from '../../../components/PrettifiedJSONViewer';

const ProductFeedImport = ({ onImport }) => {
  const [feedURL, setFeedURL] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchFeed = async () => {
    setLoading(true);
    try {
      const response = await axios.get(feedURL);
      const domainRegex =
        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/im;
      const match = feedURL.match(domainRegex);
      const domainName = match ? match[1] : 'New Source';
      onImport(response.data.products, domainName);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Import from Product Feed
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please enter a JSON product feed below.
      </Typography>
      <Grid container mt={2} mb={2} spacing={2} alignItems="center">
        <Grid item xs={8}>
          <TextField
            label="Product Feed URL"
            variant="outlined"
            fullWidth
            value={feedURL}
            onChange={(e) => setFeedURL(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchFeed}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Fetch'}
          </Button>
        </Grid>
      </Grid>
      {feedURL && (
        <>
          <Typography variant="h6" gutterBottom>
            Feed Preview
          </Typography>
          <PrettifiedJSONViewer feedUrl={feedURL} />
        </>
      )}
    </Box>
  );
};

export default ProductFeedImport;
