import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  IconButton,
} from '@mui/material';
import { useAuthInfo } from '@propelauth/react';
import SchemaContext from '../SchemaContext';
import { useNavigate } from 'react-router-dom';
import EnrichData from './CreateSourceSteps/EnrichData';
import SourceTypeSelection from './CreateSourceSteps/SourceTypeSelection';
import DataImport from './CreateSourceSteps/DataImport';
import DataMapping from './CreateSourceSteps/DataMapping';
import SourceDetails from './CreateSourceSteps/SourceDetails';
import { FaArrowLeft } from 'react-icons/fa';
// import EnrichData from './CreateSourceSteps/EnrichData';

const CreateSourceFlow = () => {
  const navigate = useNavigate();
  const { accessToken, orgHelper } = useAuthInfo();
  const { schema } = useContext(SchemaContext);

  const [activeStep, setActiveStep] = useState(0);
  const [sourceType, setSourceType] = useState('');
  const [csvData, setCsvData] = useState(null);
  const [uploadedAssetId, setUploadedAssetId] = useState(null);
  const [dataMap, setDataMap] = useState(null);
  const [mapLoading, setMapLoading] = useState(false);
  const [sourceName, setSourceName] = useState('');
  const [schedule, setSchedule] = useState('MANUAL');
  const [deduplicationField, setDeduplicationField] = useState('');
  const [scrapeURL, setScrapeURL] = useState('');
  const [shopifyURL, setShopifyURL] = useState('');
  const [rowsToEnrich, setRowsToEnrich] = useState({});

  const steps = [
    'Select Source Type',
    'Import Data',
    'Map Fields',
    'Enrich Data',
    'Source Details',
  ];

  const isDemo = orgHelper.getOrgs()[0]?.orgMetadata.demo;

  const isStepOptional = (step) => step === 3;
  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleSourceTypeSelect = (type) => {
    if (type === 'SHOPIFY') {
      setDeduplicationField('ShopifyID');
    }
    setSourceType(type);
    handleNext();
  };

  const mapCSVData = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/ai/map`,
        {
          inputData: data,
          sourceType: sourceType,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      return response.data.map_data;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDataImport = async (data, sourceName) => {
    setCsvData(data);
    setSourceName(sourceName);
    // Trigger AI mapping here
    setMapLoading(true);
    handleNext();
    const mappedData = await mapCSVData(data);
    setDataMap(mappedData);
    setMapLoading(false);
  };

  const handleDataMapping = (newMapping) => {
    setDataMap(newMapping);
    handleNext();
  };

  const createSource = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/sources/create-source`,
        {
          name: sourceName,
          type: sourceType,
          url: sourceType === 'SCRAPE' ? scrapeURL : shopifyURL,
          schedule: schedule,
          data_map: dataMap,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      return response.data.source_id;
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const addProductsToDB = async (sourceId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/products/write-to-db`,
        {
          csv: csvData,
          dataMap,
          sourceId,
          uploadedAssetId,
          dedupAttribute: deduplicationField,
        }, // Data payload
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      alert('Products imported to catalog successfully!');
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Error importing products to catalog';
      alert(errorMessage);
    }
  };

  const handleSourceDetailsSubmit = async () => {
    const sourceId = await createSource();
    await addProductsToDB(sourceId);
    navigate('/products');
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SourceTypeSelection
            onSelect={handleSourceTypeSelect}
            isDemo={isDemo}
          />
        );
      case 1:
        return (
          <DataImport
            sourceType={sourceType}
            onImport={handleDataImport}
            scrapeURL={scrapeURL}
            shopifyURL={shopifyURL}
            setScrapeURL={setScrapeURL}
            setShopifyURL={setShopifyURL}
            setUploadedAssetId={setUploadedAssetId}
          />
        );
      case 2:
        return (
          <DataMapping
            csvData={csvData}
            schema={schema}
            loading={mapLoading}
            initialMapping={dataMap}
            onMappingComplete={handleDataMapping}
            sourceType={sourceType}
          />
        );
      case 3:
        return (
          <EnrichData
            dataMap={dataMap}
            uploadedData={csvData}
            selectedRows={rowsToEnrich}
            setSelectedRows={setRowsToEnrich}
          />
        );
      case 4:
        return (
          <SourceDetails
            sourceName={sourceName}
            setSourceName={setSourceName}
            schedule={schedule}
            setSchedule={setSchedule}
            deduplicationField={deduplicationField}
            setDeduplicationField={setDeduplicationField}
            sourceType={sourceType}
            onSubmit={handleSourceDetailsSubmit}
            dataMap={dataMap}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Box mb={2} display="flex" alignItems="center">
        <IconButton
          onClick={() => navigate('/sources')}
          aria-label="go back"
          size="large"
        >
          <FaArrowLeft />
        </IconButton>
        <Typography variant="h4">Create Source</Typography>
      </Box>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {renderStepContent(activeStep)}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          {activeStep !== 0 && <Button onClick={handleBack}>Back</Button>}
          {isStepOptional(activeStep) && (
            <Button color="inherit" onClick={handleNext} sx={{ mr: 1 }}>
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateSourceFlow;
