import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  styled,
} from '@mui/material';
import {
  FaUpload,
  FaGlobe,
  FaFileImport,
  FaFilePdf,
  FaEnvelope,
  FaExclamationCircle,
} from 'react-icons/fa';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 140, // Fixed height instead of aspect ratio
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  fontSize: '5rem', // Increased from 4rem to 5rem
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}));

const sourceTypes = [
  {
    id: 'FILE_UPLOAD',
    name: 'CSV Upload',
    description: 'Upload a CSV file containing your product data.',
    icon: <FaUpload fontSize="inherit" />,
  },
  {
    id: 'SCRAPE',
    name: 'Web Scrape',
    description: 'Extract product data from a website URL.',
    icon: <FaGlobe fontSize="inherit" />,
  },
  {
    id: 'UNSTRUCTURED',
    name: 'Unstructured Files',
    description:
      'Upload PDFs, Documents, or Data Sheets containing products.',
    icon: <FaFilePdf />,
  },
  {
    id: 'SHOPIFY',
    name: 'Shopify',
    description: 'Import products directly from your Shopify store.',
    icon: (
      <img
        src="/shopify_glyph.png"
        alt="Shopify Logo"
        style={{ width: '50px', marginRight: '8px' }}
      />
    ),
  },
  {
    id: 'PRODUCT_FEED',
    name: 'Product Feed',
    description: 'Import products regularly from a product feed.',
    icon: <FaFileImport />,
  },
  {
    id: 'SUPPLIER_PORTAL',
    name: 'Supplier Portal',
    description: 'Invite a supplier to upload products via a secure portal.',
    icon: <FaEnvelope />,
  },
];

const SourceTypeSelection = ({ onSelect, isDemo }) => {
  return (
    <Box>
      {isDemo && (
        <Box mt={2}>
          <Box display="flex" alignItems="center" mb={4}>
            <FaExclamationCircle />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Demo mode is limited to storing up to 50 products. Scraping is
              limited to 10 products at a time.
            </Typography>
          </Box>
        </Box>
      )}
      <Grid container spacing={3} mb={3}>
        {sourceTypes.map((sourceType) => (
          <Grid item xs={12} sm={6} md={4} key={sourceType.id}>
            <StyledCard>
              <CardActionArea onClick={() => onSelect(sourceType.id)}>
                <StyledCardMedia>
                  <IconWrapper>{sourceType.icon}</IconWrapper>
                </StyledCardMedia>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {sourceType.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {sourceType.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Typography variant="body2" color="text.secondary">
        Don't see an option listed here? Contact{' '}
        <a href="mailto:ray@anglercloud.com">ray@anglercloud.com</a>; we're
        happy to handle special requests!
      </Typography>
    </Box>
  );
};

export default SourceTypeSelection;
