import { Paper, Skeleton } from '@mui/material';

const GridLoadingSkeleton = () => (
  <Paper style={{ marginTop: '20px', padding: '16px' }}>
    {/* Header skeleton */}
    <Skeleton
      variant="rectangular"
      height={40}
      style={{ marginBottom: '16px' }}
    />

    {/* Rows skeletons */}
    {[...Array(10)].map((_, index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        height={40}
        style={{ marginBottom: '8px' }}
      />
    ))}

    {/* Pagination skeleton */}
    <Skeleton
      variant="rectangular"
      height={30}
      width={300}
      style={{ marginTop: '16px' }}
    />
  </Paper>
);

export default GridLoadingSkeleton;
