import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useAuthInfo } from '@propelauth/react';
import SchemaContext from '../SchemaContext';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import axios from 'axios';
import {
  Button,
  TextField,
  Box,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
} from '@mui/material';
import { FaMagic, FaFileDownload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const LoadingCellRenderer = (props) => {
  if (props.value === undefined) {
    return <Skeleton variant="text" width="100%" height={20} />;
  }
  return props.value;
};

export default function Products() {
  const { accessToken, orgHelper } = useAuthInfo();
  const { schema } = useContext(SchemaContext);
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [enrichLoading, setEnrichLoading] = useState(false);
  const [enrichAttribute, setEnrichAttribute] = useState('');
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const gridRef = useRef();
  const navigate = useNavigate();

  const initialCreateProductState = Object.entries(schema).reduce(
    (acc, [id, { name }]) => {
      if (['Name', 'SKU', 'URL'].includes(name)) {
        acc[id] = { value: '' };
      }
      return acc;
    },
    {}
  );

  const [createProductState, setCreateProductState] = useState(
    initialCreateProductState
  );

  useEffect(() => {
    if (schema) {
      const newColumnDefs = [
        {
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          width: 50,
          pinned: 'left',
          colId: 'checkboxColumn',
          sortable: false,
          filter: false,
        },
        {
          headerName: 'Thumbnail',
          field: 'image',
          cellRenderer: (params) =>
            params.value ? (
              <img
                src={params.value}
                alt="thumbnail"
                style={{ width: '50px', height: '50px' }}
              />
            ) : null,
          width: 120,
        },
        {
          headerName: 'Status',
          field: 'needsReview',
          cellRenderer: (params) =>
            params.value === undefined ? (
              <Skeleton variant="text" width="100%" height={20} />
            ) : params.value ? (
              'Needs Review'
            ) : (
              'OK'
            ),
          width: 120,
        },
        ...Object.keys(schema).map((key) => ({
          headerName: schema[key].name,
          field: schema[key].name,
          sortable: true,
          filter: true,
          cellRenderer: LoadingCellRenderer,
          pinned: schema[key].name === 'Name' ? 'left' : undefined,
        })),
        {
          headerName: 'Source',
          field: 'source',
          sortable: false,
          filter: false,
          cellRenderer: LoadingCellRenderer,
        },
        {
          headerName: 'Created At',
          field: 'created_at',
          sortable: true,
          cellRenderer: (params) =>
            params.value === undefined ? (
              <Skeleton variant="text" width="100%" height={20} />
            ) : (
              new Date(params.value).toLocaleString()
            ),
        },
        {
          headerName: 'Updated At',
          field: 'updated_at',
          sortable: true,
          cellRenderer: (params) =>
            params.value === undefined ? (
              <Skeleton variant="text" width="100%" height={20} />
            ) : (
              new Date(params.value).toLocaleString()
            ),
        },
      ];
      setColumnDefs(newColumnDefs);
    }
  }, [schema]);

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);

      const datasource = {
        getRows: (params) => {
          const { startRow, endRow, sortModel, filterModel } = params;
          const queryParams = new URLSearchParams({
            page: Math.floor(startRow / 100) + 1,
            limit: endRow - startRow,
            sort: sortModel.length > 0 ? sortModel[0].colId : 'updated_at',
            order: sortModel.length > 0 ? sortModel[0].sort : 'desc',
          });

          if (filterModel) {
            const firstFilterField = Object.keys(filterModel)[0];
            if (firstFilterField) {
              queryParams.append('filterField', firstFilterField);
              queryParams.append(
                'filterValue',
                filterModel[firstFilterField].filter
              );
            }
          }

          axios
            .get(
              `${process.env.REACT_APP_API_URL}/org/${
                orgHelper.getOrgs()[0].orgId
              }/products/get-all?${queryParams}`,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            )
            .then((response) => {
              params.successCallback(
                response.data.products,
                response.data.totalCount
              );
            })
            .catch((error) => {
              params.failCallback();
              console.error('Error fetching products:', error);
            });
        },
      };

      params.api.setDatasource(datasource);
    },
    [accessToken, orgHelper]
  );

  const onSelectionChanged = useCallback(() => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedNodes.map((node) => node.data.id);
    setSelectedIds(selectedIds);
  }, []);

  const handleEnrich = async () => {
    if (!enrichAttribute) {
      alert('Please select an attribute to enrich');
      return;
    }
    setEnrichLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/ai/enrich`,
        {
          attributes: [enrichAttribute],
          products: selectedIds,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      gridRef.current.api.refreshInfiniteCache();
    } catch (error) {
      console.error('Error enriching products:', error);
      alert('Failed to enrich products. Please try again.');
    } finally {
      setEnrichLoading(false);
    }
  };

  const downloadAsCsv = useCallback(() => {
    gridRef.current.api.exportDataAsCsv({
      onlySelected: true,
      fileName: 'products_export.csv',
    });
  }, []);

  const handleCellClicked = (event) => {
    if (event.column.colId === 'checkboxColumn') return;
    if (!event.data) return;
    navigate(`/products/${event.data.id}`);
  };

  const handleCreateProductInputChange = (id) => (event) => {
    setCreateProductState((prevState) => ({
      ...prevState,
      [id]: { value: event.target.value },
    }));
  };

  const handleCreateProductClicked = () => {
    setIsProductModalOpen(true);
  };

  const handleCloseProductModal = () => {
    setIsProductModalOpen(false);
    setCreateProductState(initialCreateProductState);
  };

  const handleCreateProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/products/create-or-update`,
        {
          productData: createProductState,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const newProductId = response.data.id;
      // handleCloseProductModal();
      navigate(`/products/${newProductId}`);
    } catch (error) {
      console.error('Error creating product:', error);
      alert('Failed to create product. Please try again.');
    }
  };

  return (
    <div>
      <Typography variant="h4">Products</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" alignItems="center">
          {selectedIds.length > 0 && (
            <>
              <Select
                value={enrichAttribute}
                onChange={(e) => setEnrichAttribute(e.target.value)}
                displayEmpty
                style={{ marginRight: '10px', minWidth: '200px' }}
              >
                <MenuItem value="" disabled>
                  -- Select Attribute --
                </MenuItem>
                {Object.keys(schema)
                  .filter((key) => schema[key].enrichType !== 'NONE')
                  .map((attribute) => (
                    <MenuItem key={attribute} value={attribute}>
                      {schema[attribute].name}
                    </MenuItem>
                  ))}
              </Select>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FaMagic />}
                onClick={handleEnrich}
                disabled={!enrichAttribute || enrichLoading}
                style={{ marginRight: '10px' }}
              >
                {enrichLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  `Enrich (${selectedIds.length})`
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FaFileDownload />}
                onClick={downloadAsCsv}
              >
                Export to CSV ({selectedIds.length})
              </Button>
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateProductClicked}
          >
            + Create Product
          </Button>
        </Box>
      </Box>
      <div
        className="ag-theme-quartz"
        style={{ height: 'calc(100vh - 200px)', width: '100%' }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowModelType={'infinite'}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onCellClicked={handleCellClicked}
          rowSelection={'multiple'}
          pagination={true}
          paginationPageSize={100}
          cacheBlockSize={100}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={1000}
          maxBlocksInCache={10}
        />
      </div>
      <Dialog open={isProductModalOpen} onClose={handleCloseProductModal}>
        <DialogTitle>Create New Product</DialogTitle>
        <DialogContent>
          {Object.entries(createProductState).map(([id, { value }]) => (
            <TextField
              key={id}
              label={schema[id].name}
              value={value}
              onChange={handleCreateProductInputChange(id)}
              fullWidth
              margin="normal"
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProductModal}>Cancel</Button>
          <Button
            onClick={handleCreateProduct}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
