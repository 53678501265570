import React, { useState } from 'react';
import { Box, TextField, Button, Chip } from '@mui/material';
import { FaPlus } from 'react-icons/fa';

const InlineAttributeAddition = ({
  variantAttributes,
  onAddAttribute,
  onRemoveAttribute,
}) => {
  const [newAttribute, setNewAttribute] = useState('');

  const handleAddAttribute = () => {
    if (newAttribute && !variantAttributes.includes(newAttribute)) {
      onAddAttribute(newAttribute);
      setNewAttribute('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if within a form
      handleAddAttribute();
    }
  };

  return (
    <Box>
      <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
        {variantAttributes.map((attr) => (
          <Chip
            key={attr}
            label={attr}
            onDelete={() => onRemoveAttribute(attr)}
            sx={{ mb: 1 }}
          />
        ))}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <TextField
          size="small"
          value={newAttribute}
          onChange={(e) => setNewAttribute(e.target.value)}
          placeholder="New attribute"
          onKeyDown={handleKeyDown}
        />
        <Button
          onClick={handleAddAttribute}
          size="small"
          variant="contained"
          disabled={!newAttribute.trim()}
        >
          + Add
        </Button>
      </Box>
    </Box>
  );
};

export default InlineAttributeAddition;
