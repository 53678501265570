import React, { useState, useEffect } from 'react';
import { triggerConfetti } from '../confettiEffect';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Tooltip,
} from '@mui/material';
import { FaPlus, FaEdit, FaTrash, FaInfoCircle, FaMagic } from 'react-icons/fa';
import InlineAttributeAddition from './InlineAttributeAddition';
import VariantFormModal from './VariantFormModal';

const VariantManagement = ({
  productId,
  variants,
  setVariants,
  variantAttributes,
  setVariantAttributes,
}) => {
  const { accessToken, orgHelper } = useAuthInfo();
  const [editingVariant, setEditingVariant] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [enrichLoading, setEnrichLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVariantData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/products/variants/${productId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setVariantAttributes(response.data.variant_attributes);
        setVariants(response.data.variants);
      } catch (error) {
        setError(error);
        console.error('Error fetching variant data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (productId) {
      fetchVariantData();
    }
  }, []);

  const handleAddAttribute = async (newAttribute) => {
    if (newAttribute && !variantAttributes.includes(newAttribute)) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/products/variant-attributes/add/${productId}`,
          { attribute: newAttribute },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Authorization header
            },
          }
        );
        setVariantAttributes([...variantAttributes, newAttribute]);
      } catch (error) {
        console.error('Error adding attribute:', error);
      }
    }
  };

  const handleRemoveAttribute = async (attribute) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/products/variant-attributes/delete/${productId}`,
        { attribute },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      setVariantAttributes(
        variantAttributes.filter((attr) => attr !== attribute)
      );
      setVariants(
        variants.map((variant) => {
          const { [attribute]: removed, ...rest } = variant;
          return rest;
        })
      );
    } catch (error) {
      console.error('Error removing attribute:', error);
    }
  };

  const handleOpenModal = (variant = null) => {
    console.log(variant);
    setEditingVariant(variant);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setEditingVariant(null);
    setOpenModal(false);
  };

  const handleSaveVariant = async (formData) => {
    try {
      if (editingVariant) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/products/variants/update/${editingVariant.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setVariants(
          variants.map((v) =>
            v.id === editingVariant.id ? { id: v.id, ...formData } : v
          )
        );
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/products/variants/create/${productId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setVariants([...variants, { id: response.data.id, ...formData }]);
      }
    } catch (error) {
      console.error('Failed to save variant:', error);
    }
  };
  const handleDeleteVariant = (variant) => {
    console.log(variant);
    setDeleteConfirmation(variant);
  };

  const confirmDeleteVariant = async () => {
    if (deleteConfirmation) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/products/variants/delete/${deleteConfirmation}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setVariants(variants.filter((v) => v.id !== deleteConfirmation));
        setDeleteConfirmation(null);
      } catch (error) {
        console.error('Failed to delete variant:', error);
      }
    }
  };

  const handleEnrichVariants = async () => {
    setEnrichLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/ai/enrich-variants/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      triggerConfetti();
      setVariantAttributes(response.data.variant_attributes);
      setVariants(response.data.variants);
    } catch (error) {
      console.error('Failed to enrich variants:', error);
    } finally {
      setEnrichLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error.message}</Typography>;
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          display="flex"
          alignItems="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            m: 0, // Remove default margin
          }}
        >
          Variant Attributes
          <Tooltip title="Attributes define the characteristics that can vary between product variants, such as color or size.">
            <Box component="span" ml={1}>
              <FaInfoCircle />
            </Box>
          </Tooltip>
        </Typography>
        <IconButton
          color="primary"
          size="large"
          onClick={handleEnrichVariants}
          disabled={enrichLoading}
        >
          {enrichLoading ? (
            <CircularProgress size={20} />
          ) : (
            <FaMagic size={20} />
          )}
        </IconButton>
      </Box>
      <InlineAttributeAddition
        variantAttributes={variantAttributes}
        onAddAttribute={handleAddAttribute}
        onRemoveAttribute={handleRemoveAttribute}
      />

      <Divider sx={{ my: 2 }} />

      <Typography
        variant="subtitle1"
        gutterBottom
        display="flex"
        alignItems="center"
      >
        Product Variants
        <Tooltip title="Variants are specific combinations of attributes, each representing a unique version of your product.">
          <Box component="span" ml={1}>
            <FaInfoCircle />
          </Box>
        </Tooltip>
      </Typography>

      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        {variants.length > 0 ? (
          <TableContainer component={Paper} sx={{ maxHeight: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  {variantAttributes.map((attr) => (
                    <TableCell key={attr}>{attr}</TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {variants.map((variant) => (
                  <TableRow key={variant.id}>
                    <TableCell>{variant.sku}</TableCell>
                    {variantAttributes.map((attr) => (
                      <TableCell key={attr}>
                        {variant.variant_attributes[attr]}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button
                        startIcon={<FaEdit />}
                        onClick={() => handleOpenModal(variant)}
                      >
                        Edit
                      </Button>
                      <Button
                        startIcon={<FaTrash />}
                        onClick={() => handleDeleteVariant(variant.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box textAlign="center" py={4}>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              No variants added yet
            </Typography>
            <Typography color="textSecondary" mb={2}>
              After adding attributes, click "Add Variant" to create your first
              product variant.
            </Typography>
          </Box>
        )}
      </Box>

      <Box mt={2}>
        <Button
          variant="contained"
          onClick={() => handleOpenModal()}
          disabled={variantAttributes.length === 0}
        >
          + Add Variant
        </Button>
      </Box>

      {/* <Dialog
        open={Boolean(editingVariant)}
        onClose={() => setEditingVariant(null)}
      >
        <DialogTitle>Edit Variant</DialogTitle>
        <DialogContent>
          {editingVariant && (
            <Box>
              <TextField
                margin="dense"
                label="SKU"
                fullWidth
                value={editingVariant.sku}
                onChange={(e) =>
                  setEditingVariant({ ...editingVariant, sku: e.target.value })
                }
              />
              {variantAttributes.map((attr) => (
                <TextField
                  key={attr}
                  margin="dense"
                  label={attr}
                  fullWidth
                  value={editingVariant[attr] || ''}
                  onChange={(e) =>
                    setEditingVariant({
                      ...editingVariant,
                      [attr]: e.target.value,
                    })
                  }
                />
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingVariant(null)}>Cancel</Button>
          <Button onClick={handleSaveVariant} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog> */}

      <VariantFormModal
        open={openModal}
        onClose={handleCloseModal}
        onSave={handleSaveVariant}
        variant={editingVariant}
        variantAttributes={variantAttributes}
      />

      <Dialog
        open={Boolean(deleteConfirmation)}
        onClose={() => setDeleteConfirmation(null)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this variant?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
          <Button
            onClick={confirmDeleteVariant}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VariantManagement;
