import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Link,
} from '@mui/material';
import { FaExternalLinkAlt } from 'react-icons/fa';

export default function Shopify({ onImport, shopifyURL, setShopifyURL }) {
  const { accessToken, orgHelper } = useAuthInfo();
  const [loading, setLoading] = useState(false);

  const isShopifyEnabled = orgHelper.getOrgs()[0]?.orgMetadata?.SHOPIFY_KEY;

  // TODO: Refactor this out; move to custom app model without OAuth
  //   useEffect(() => {
  //     const urlParams = new URLSearchParams(window.location.search);

  //     // Check if we're returning from the Shopify OAuth flow
  //     if (
  //       urlParams.has('success') &&
  //       urlParams.get('success') === 'true' &&
  //       !csvData
  //     ) {
  //       // Fetch products after successful authentication
  //       fetchShopifyProducts();
  //     }
  //   }, []);

  //   const fetchShopifyProducts = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}/integrations/get-shopify-products`, {
  //         withCredentials: true,
  //       });

  //       onImport(response.data, shopifyURL);
  //     } catch (error) {
  //       console.log('Error fetching products:', error);
  //     } finally {
  //         setLoading(false);
  //     }
  //   };

  //   const initiateShopifyAuth = async () => {
  //     if (!/^https:\/\/[\w-]+\.myshopify\.com\//.test(shopifyURL)) {
  //       alert('Please enter a valid Shopify URL!');
  //       return;
  //     }
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_URL}/shopify/auth`,
  //         {
  //           shop_url: shopifyURL,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         }
  //       );
  //       window.location.href = response.data.auth_url;
  //     } catch (error) {
  //       console.error('Error initiating authentication:', error);
  //     }
  //   };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/integrations/get-shopify-products`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      console.log(response.data.products);
      onImport(response.data.products, shopifyURL);
    } catch (error) {
      console.log('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isShopifyEnabled)
    return (
      <Box sx={{ mt: 3, mb: 3 }}>
        <Typography variant='h6' gutterBottom>
          Import from Shopify
        </Typography>
        <Typography variant='body1' gutterBottom>
          Shopify integration is not enabled on this organization. Please
          contact ray@anglercloud.com for support.
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Typography variant='h6' gutterBottom>
        Import from Shopify
      </Typography>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={8}>
          <TextField
            label='Shopify Store URL'
            variant='outlined'
            fullWidth
            value={orgHelper.getOrgs()[0]?.orgMetadata?.SHOPIFY_URL}
            onChange={(e) => setShopifyURL(e.target.value)}
            disabled
            InputProps={{
              endAdornment: (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '64px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    color='primary'
                    size='small'
                    component={Link}
                    href={orgHelper.getOrgs()[0]?.orgMetadata?.SHOPIFY_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FaExternalLinkAlt size={18} />
                  </IconButton>
                </Box>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            color='primary'
            startIcon={
              <img
                src='/shopify_glyph.png'
                alt='Shopify Logo'
                style={{ width: '24px', marginRight: '8px' }}
              />
            }
            onClick={fetchProducts}
            disabled={loading}
            sx={{
              fontWeight: 'normal',
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Import from Shopify'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
