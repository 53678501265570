import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ManageAttributes from './ManageAttributes';
import CreateAttribute from './CreateAttribute';
import AttributeDetails from './AttributeDetails';

function Attributes() {
  return (
    <div>
      <Routes>
        <Route index element={<ManageAttributes />} />
        <Route path="create" element={<CreateAttribute />} />
        <Route path=":id" element={<AttributeDetails />} />
      </Routes>
    </div>
  );
}

export default Attributes;
