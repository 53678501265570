import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Tabs,
  Tab,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';
import { FaCopy, FaCheck, FaExclamationCircle } from 'react-icons/fa';
import AgGridProductSelect from '../components/AgGridProductSelect';

const ChannelTab = ({ children, value, index, ...other }) => (
  <div hidden={value !== index} {...other}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const SourceList = ({ sources, onSourceChange }) => (
  <Box sx={{ maxHeight: 200, overflowY: 'auto', my: 2 }}>
    {sources.map((source) => (
      <FormControlLabel
        key={source.id}
        control={
          <Checkbox
            checked={source.checked}
            onChange={(e) => onSourceChange(source.id, e.target.checked)}
          />
        }
        label={`${source.name} (${source.num_products} products)`}
      />
    ))}
  </Box>
);

const FeedUrlDisplay = ({ url, onCopy }) => (
  <TextField
    fullWidth
    value={url}
    InputProps={{
      readOnly: true,
      endAdornment: (
        <Button startIcon={<FaCopy />} onClick={onCopy}>
          Copy
        </Button>
      ),
    }}
  />
);

const StatusIndicator = ({ status }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'success':
        return 'success.main';
      case 'error':
        return 'error.main';
      default:
        return 'text.secondary';
    }
  };

  const getStatusIcon = () => {
    switch (status) {
      case 'success':
        return <FaCheck />;
      case 'error':
        return <FaExclamationCircle />;
      default:
        return null;
    }
  };

  return (
    <Box display='flex' alignItems='center' color={getStatusColor()}>
      {getStatusIcon()}
      <Typography variant='body2' sx={{ ml: 1 }}>
        {status === 'success'
          ? 'Sync successful'
          : status === 'error'
          ? 'An error occurred'
          : 'Not synced'}
      </Typography>
    </Box>
  );
};

export default function CreateChannel() {
  const { accessToken, orgHelper, isLoggedIn } = useAuthInfo();
  const [activeTab, setActiveTab] = useState(0);
  const [products, setProducts] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [syncStatus, setSyncStatus] = useState('idle');

  const isShopifyEnabled =
    isLoggedIn && orgHelper.getOrgs()[0]?.orgMetadata?.SHOPIFY_KEY;

  const isBigCommerceEnabled =
    isLoggedIn && orgHelper.getOrgs()[0]?.orgMetadata?.BIGCOMMERCE_KEY;

  useEffect(() => {
    if (isLoggedIn) {
      fetchProducts();
    }
  }, []);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/products/get-names`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setProducts(response.data.products);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleShopifySync = async () => {
    try {
      if (!isShopifyEnabled) {
        setSyncStatus('error');
        return;
      }
      setSyncStatus('syncing');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/integrations/write-shopify-products`,
        {
          ids: selectedProducts,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      console.log(response.data);
      setSyncStatus('success');
    } catch (error) {
      setSyncStatus('error');
      console.error('Error:', error);
    }
  };

  const handleBigCommerceSync = async () => {
    try {
      setSyncStatus('syncing');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/integrations/write-bigcommerce-products`,
        {
          ids: selectedProducts,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      console.log(response.data);
      setSyncStatus('success');
    } catch (error) {
      setSyncStatus('error');
      console.error('Error:', error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Typography gutterBottom variant='h4'>
        Channels
      </Typography>
      <AgGridProductSelect
        products={products}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
        {isShopifyEnabled && (
          <Button
            sx={{ mr: 2 }}
            startIcon={
              syncStatus === 'syncing' ? (
                <CircularProgress size='1rem' />
              ) : (
                <img
                  src='/shopify_glyph.png'
                  alt='Shopify Logo'
                  style={{ width: '20px' }}
                />
              )
            }
            variant='contained'
            color='primary'
            onClick={handleShopifySync}
            disabled={syncStatus === 'syncing' || selectedProducts.length === 0}
          >
            {syncStatus === 'syncing' ? 'Syncing...' : 'Sync to Shopify'}
          </Button>
        )}
        {isBigCommerceEnabled && (
          <Button
            startIcon={
              syncStatus === 'syncing' ? (
                <CircularProgress size='1rem' />
              ) : (
                <img
                  src='/BigCommerceLogo.png'
                  alt='BigCommerce Logo'
                  style={{ width: '20px' }}
                />
              )
            }
            variant='contained'
            color='primary'
            onClick={handleBigCommerceSync}
            disabled={syncStatus === 'syncing' || selectedProducts.length === 0}
          >
            {syncStatus === 'syncing' ? 'Syncing...' : 'Sync to BigCommerce'}
          </Button>
        )}
      </Box>
      <Box mt={2}>
        <StatusIndicator status={syncStatus} />
      </Box>
    </>
  );
}
