import React, { useState } from 'react';
import { FaFilePdf, FaPlus } from 'react-icons/fa';
import { Button, Paper, Box, Typography } from '@mui/material';
import { useAuthInfo } from '@propelauth/react';
import AssetUploadModal from '../../components/AssetUploadModal';

const AssetsList = ({ productId, assets, setAssets }) => {
  const [open, setOpen] = useState(false);
  const handleCreateAsset = (uploadedAsset) => {
    setAssets((prevAssets) => [...prevAssets, uploadedAsset]);
  };
  return (
    <>
      {assets.length === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          p={3}
        >
          <Typography variant="h6" gutterBottom>
            No Assets Available
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            mb={3}
          >
            Start building your collection by uploading and linking images,
            documents, or other files.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            + Add New Asset
          </Button>
        </Box>
      )}
      {assets.map((asset, index) => (
        <Paper key={index} elevation={1} sx={{ mb: 2, p: 2 }}>
          <Box display="flex" alignItems="center" sx={{ gap: 2 }}>
            <FaFilePdf size={32} color="#d32f2f" />
            <Typography>{asset.name}</Typography>
          </Box>
        </Paper>
      ))}
      <AssetUploadModal
        open={open}
        onClose={() => setOpen(false)}
        acceptedFormats={['pdf']}
        onAssetUploaded={handleCreateAsset}
        linkedProductId={productId}
      />
    </>
  );
};

export default AssetsList;
