import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import FullScreenLoader from './components/FullScreenLoader';
import { BrowserRouter } from 'react-router-dom';
import { RequiredAuthProvider } from '@propelauth/react';
import { SchemaProvider } from './SchemaContext';

const theme = createTheme({
  // typography: {
  //   fontFamily: "'Cabinet Grotesk', sans-serif",
  // },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RequiredAuthProvider
      authUrl={
        process.env.NODE_ENV === 'development'
          ? 'https://2328387647.propelauthtest.com'
          : 'https://auth.anglera.com'
      }
      displayWhileLoading={<FullScreenLoader />}
    >
      <SchemaProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />{' '}
            {/* This component ensures that the styles reset to material default */}
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </SchemaProvider>
    </RequiredAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
