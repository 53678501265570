import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';

const pulse = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FullScreenWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: 'background.default',
  animation: `${fadeIn} 0.5s ease-out`,
});

const LogoWrapper = styled(Box)({
  marginBottom: (theme) => theme.spacing(4),
  animation: `${pulse} 2s infinite ease-in-out`,
});

const FullScreenLoader = () => {
  return (
    <FullScreenWrapper>
      <LogoWrapper>
        <Box
          component="img"
          src="/AngleraLogo.png"
          alt="Company Logo"
          sx={{ maxWidth: 300 }}
          mb={2}
        />
      </LogoWrapper>
      <CircularProgress size={48} sx={{ mb: 2 }} />
      <Typography variant="h6" color="text.secondary">
        Loading...
      </Typography>
    </FullScreenWrapper>
  );
};

export default FullScreenLoader;
