// App.js

import React, { useEffect, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import SchemaContext from './SchemaContext';
import NavBar from './NavBar/NavBar';
import Home from './Home/Home';
import Sources from './Data/Sources';
import Products from './Products/Products';
import Attributes from './Attributes/Attributes';
import Onboarding from './Onboarding/Onboarding';
import Channels from './Channels/Channels';
import Assets from './Assets/Assets';
import Assistant from './Assistant/Assistant';
import FullScreenLoader from './components/FullScreenLoader';
import LandingRedirect from './LandingRedirect/LandingRedirect';

function App() {
  const { schemaLoading, schema } = useContext(SchemaContext);

  useEffect(() => {
    document.title = 'Anglera';
  }, []);

  if (schemaLoading) {
    return <FullScreenLoader />;
  }
  if (Object.keys(schema).length === 0) {
    return <Onboarding />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <NavBar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - 240px)` },
          maxWidth: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={<Home />} />
          <Route path="/sources/*" element={<Sources />} />
          <Route path="/products/*" element={<Products />} />
          <Route path="/attributes/*" element={<Attributes />} />
          <Route path="/channels/*" element={<Channels />} />
          <Route path="/assets" element={<Assets />} />
          <Route path="/assistant" element={<Assistant />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/landing" element={<LandingRedirect />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
