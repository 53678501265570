import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  AppBar,
  Toolbar,
  CircularProgress,
  Checkbox,
  Pagination,
  Skeleton,
} from '@mui/material';
import LinkProductsModal from './LinkProductsModal';
import {
  FaUpload,
  FaTrash,
  FaFilePdf,
  FaLink,
  FaList,
  FaThLarge,
} from 'react-icons/fa';
import AssetUploadModal from '../components/AssetUploadModal';
import AssetViewer from '../components/AssetViewer';

const ITEMS_PER_PAGE = 25;

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  paddingTop: '100%',
  backgroundSize: 'contain',
}));

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

const AssetList = React.memo(({ assets, onSelectionChanged, onRowClicked }) => {
  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'checkbox',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 50,
      },
      { headerName: 'Name', field: 'name', maxWidth: 350 },
      { headerName: 'Type', field: 'type' },
      {
        headerName: 'Preview',
        field: 'url',
        cellRenderer: (params) => {
          if (params.data.type === 'application/pdf') {
            return <FaFilePdf style={{ fontSize: '24px', color: '#d32f2f' }} />;
          } else {
            return (
              <img
                src={params.value}
                alt={params.data.name}
                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
              />
            );
          }
        },
      },
    ],
    []
  );

  return (
    <div className="ag-theme-quartz" style={{ height: '800px', width: '100%' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={assets}
        rowSelection="multiple"
        onSelectionChanged={onSelectionChanged}
        onRowClicked={onRowClicked}
        suppressRowClickSelection={true}
      />
    </div>
  );
});

function Assets() {
  const { accessToken, orgHelper } = useAuthInfo();
  const [assets, setAssets] = useState([]);
  const [viewMode, setViewMode] = useState('grid');
  const [loading, setLoading] = useState(true);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [isMultiDeleteConfirmationOpen, setMultiDeleteConfirmationOpen] =
    useState(false);
  const [isLinkProductsModalOpen, setIsLinkProductsModalOpen] = useState(false);

  // Pagination state
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const fetchAssets = useCallback(
    async (pageNumber) => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/assets/get-all`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              page: pageNumber,
              limit: ITEMS_PER_PAGE,
            },
          }
        );
        console.log(Object.values(response.data.assets).createdAt);
        setAssets(
          Object.values(response.data.assets).sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );
        setTotalCount(response.data.totalCount);
        setTotalPages(Math.ceil(response.data.totalCount / ITEMS_PER_PAGE));
        setPage(pageNumber);
      } catch (error) {
        console.error('Error fetching assets:', error);
      } finally {
        setLoading(false);
      }
    },
    [accessToken, orgHelper]
  );

  useEffect(() => {
    fetchAssets(1);
  }, [fetchAssets]);

  const handlePageChange = (event, value) => {
    fetchAssets(value);
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    setSelectedAssets([]);
  };

  const handleClickOpen = (asset) => {
    setSelectedAsset(asset);
    setViewOpen(true);
  };

  const handleClose = () => {
    setViewOpen(false);
    setSelectedAsset(null);
  };

  const handleAssetSelect = (assetId) => {
    setSelectedAssets((prev) =>
      prev.includes(assetId)
        ? prev.filter((id) => id !== assetId)
        : [...prev, assetId]
    );
  };

  const onSelectionChanged = useCallback((event) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedAssets(selectedRows.map((row) => row.id));
  }, []);

  const handleMultiDelete = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/assets/delete-multiple`,
        { assetIds: selectedAssets },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchAssets(page);
      setSelectedAssets([]);
      setMultiDeleteConfirmationOpen(false);
    } catch (error) {
      console.error('Error deleting multiple assets:', error);
    }
  };

  const handleDeleteAsset = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/assets/delete/${selectedAsset.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchAssets(page);
      handleClose();
    } catch (error) {
      console.error('Error deleting asset:', error);
    }
  };

  const handleFileUpload = (uploadedAsset) => {
    fetchAssets(1); // Refresh the first page after upload
  };

  const handleLinkProductsClick = () => {
    setIsLinkProductsModalOpen(true);
  };

  const handleLinkProductsClose = () => {
    setIsLinkProductsModalOpen(false);
  };

  const handleProductsLinked = () => {
    fetchAssets(page);
    console.log('Linked!');
  };

  const LoadingSkeleton = ({ viewMode }) => {
    if (viewMode === 'list') {
      return (
        <div
          className="ag-theme-quartz"
          style={{ height: '600px', width: '100%' }}
        >
          {[...Array(ITEMS_PER_PAGE - 13)].map((_, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Skeleton
                variant="rectangular"
                width={20}
                height={20}
                sx={{ mr: 2 }}
              />
              <Skeleton variant="text" width="30%" sx={{ mr: 2 }} />
              <Skeleton variant="text" width="20%" sx={{ mr: 2 }} />
              <Skeleton variant="rectangular" width={50} height={50} />
            </Box>
          ))}
        </div>
      );
    }

    return (
      <Grid container spacing={2}>
        {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
            <Skeleton variant="rectangular" width="100%" height={140} />
            <Skeleton variant="text" />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Assets ({totalCount} total)
      </Typography>
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={viewMode === 'grid' ? <FaList /> : <FaThLarge />}
          onClick={() =>
            handleViewModeChange(viewMode === 'grid' ? 'list' : 'grid')
          }
          sx={{ mr: 2 }}
        >
          {viewMode === 'grid' ? 'List View' : 'Grid View'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaUpload />}
          onClick={() => setUploadOpen(true)}
        >
          Upload Asset
        </Button>
      </Box>
      {selectedAssets.length > 0 && (
        <AppBar position="static" color="default" elevation={0} sx={{ mb: 2 }}>
          <Toolbar>
            <Button
              startIcon={<FaTrash />}
              onClick={() => setMultiDeleteConfirmationOpen(true)}
            >
              Delete Selected
            </Button>
            <Button startIcon={<FaLink />} onClick={handleLinkProductsClick}>
              Link Products
            </Button>
          </Toolbar>
        </AppBar>
      )}
      {loading ? (
        <LoadingSkeleton viewMode={viewMode} />
      ) : assets.length === 0 ? (
        <Typography variant="body1" align="center">
          No assets found
        </Typography>
      ) : viewMode === 'list' ? (
        <AssetList
          assets={assets}
          onSelectionChanged={onSelectionChanged}
          onRowClicked={(event) => handleClickOpen(event.data)}
        />
      ) : (
        <Grid container spacing={2}>
          {assets.map((asset) => (
            <Grid item xs={6} sm={4} md={3} lg={2.4} key={asset.id}>
              <StyledCard>
                <Box position="relative">
                  <Checkbox
                    checked={selectedAssets.includes(asset.id)}
                    onChange={() => handleAssetSelect(asset.id)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 1,
                    }}
                  />
                  <CardActionArea onClick={() => handleClickOpen(asset)}>
                    {asset.type === 'application/pdf' ? (
                      <Box paddingBottom="100%" position="relative">
                        <FaFilePdf
                          style={{
                            fontSize: '90px',
                            color: '#d32f2f',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        />
                      </Box>
                    ) : (
                      <StyledCardMedia image={asset.url} title={asset.name} />
                    )}
                    <StyledCardContent>
                      <Typography variant="body2" component="p" noWrap>
                        {asset.name}
                      </Typography>
                    </StyledCardContent>
                  </CardActionArea>
                </Box>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      )}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
      <AssetViewer
        open={viewOpen}
        onClose={handleClose}
        asset={selectedAsset}
        onDelete={handleDeleteAsset}
      />
      <AssetUploadModal
        open={uploadOpen}
        onClose={() => setUploadOpen(false)}
        acceptedFormats={['pdf', 'jpg', 'jpeg', 'png']}
        onAssetUploaded={handleFileUpload}
      />
      <Dialog
        open={isMultiDeleteConfirmationOpen}
        onClose={() => setMultiDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Multiple Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {selectedAssets.length} selected
          assets?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMultiDeleteConfirmationOpen(false)}>
            No
          </Button>
          <Button onClick={handleMultiDelete} color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <LinkProductsModal
        open={isLinkProductsModalOpen}
        handleClose={handleLinkProductsClose}
        selectedAssets={selectedAssets}
        onProductsLinked={handleProductsLinked}
      />
    </Box>
  );
}

export default Assets;
