import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ManageChannels from './ManageChannels';
import CreateChannel from './CreateChannel';
import ChannelDetails from './ChannelDetails';

function Channels() {
  return (
    // <div>
    //   <Routes>
    //     <Route index element={<ManageChannels />} />
    //     <Route path="create" element={<CreateChannel />} />
    //     <Route path=":id" element={<ChannelDetails />} />
    //   </Routes>
    // </div>
    <CreateChannel />
  );
}

export default Channels;
