import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Snackbar,
} from '@mui/material';
import { FaTimes, FaTrash, FaExternalLinkAlt, FaCopy } from 'react-icons/fa';

const AssetViewer = ({ open, onClose, asset, onDelete }) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
    onDelete(asset.id);
    showSnackbar('Asset deleted successfully');
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(asset?.url).then(() => {
      showSnackbar('URL copied to clipboard');
    });
  };

  const handleDownload = () => {
    window.open(asset?.url, '_blank');
    showSnackbar('Download started');
  };

  const showSnackbar = (message) => {
    setSnackbar({ open: true, message });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const renderContent = () => {
    if (asset?.type === 'application/pdf') {
      return (
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(
            asset.url
          )}&embedded=true`}
          title={asset.name}
          width="100%"
          height="700px"
          style={{ border: 'none' }}
        />
      );
      // } else if (asset?.type.startsWith('image/')) {
    } else {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="700px"
        >
          <img
            src={asset?.url}
            alt={asset?.name}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
      );
    }
    // else {
    //   return <Typography>Unsupported file type</Typography>;
    // }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs>
              {asset?.name}
            </Grid>
            <Grid item>
              <Tooltip title="Copy URL">
                <IconButton onClick={handleCopyUrl} aria-label="Copy asset URL">
                  <FaCopy />
                </IconButton>
              </Tooltip>
              <Tooltip title="Navigate">
                <IconButton onClick={handleDownload} aria-label="Go to link">
                  <FaExternalLinkAlt />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={onClose} aria-label="Close">
                  <FaTimes />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>{renderContent()}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmationOpen(true)}
            color="error"
            startIcon={<FaTrash />}
          >
            Delete Asset
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this asset?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmation}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        message={snackbar.message}
      />
    </>
  );
};

export default AssetViewer;
