import React, { useState } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Divider,
} from '@mui/material';
import DragDropUpload from '../components/DragDropUpload';
import { useAuthInfo } from '@propelauth/react';
import axios from 'axios';

const AssetUploadModal = ({
  open,
  onClose,
  acceptedFormats,
  onAssetUploaded,
  linkedProductId = null,
}) => {
  const { accessToken, orgHelper } = useAuthInfo();
  const [assetUrl, setAssetUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');

  const handleUrlChange = (e) => {
    setAssetUrl(e.target.value);
    setError('');
  };

  function isValidUrlString(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  const uploadAsset = async (payload) => {
    setIsUploading(true);
    setError('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/assets/upload`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const uploadedAsset = response.data.uploadedAsset;
      onAssetUploaded(uploadedAsset);
      onClose();
    } catch (error) {
      console.error('Error uploading asset:', error);
      setError('Error uploading asset: ' + error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleUrlSubmit = () => {
    if (!isValidUrlString(assetUrl)) {
      setError('Invalid URL or unsupported file format');
      return;
    }
    const formData = new FormData();
    formData.append('url', assetUrl);
    if (linkedProductId) {
      formData.append('linkedProductId', linkedProductId);
    }
    uploadAsset(formData);
  };

  const handleFileUpload = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    if (linkedProductId) {
      formData.append('linkedProductId', linkedProductId);
    }
    uploadAsset(formData);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Upload Asset
        </Typography>
        <TextField
          fullWidth
          label="Asset URL"
          value={assetUrl}
          onChange={handleUrlChange}
          margin="normal"
          error={!!error}
          helperText={error}
        />
        <Button
          onClick={handleUrlSubmit}
          variant="contained"
          sx={{ mt: 2, mr: 2 }}
          disabled={isUploading || assetUrl.trim() === ''}
        >
          Upload URL
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="body1" sx={{ mx: 2 }}>
            or
          </Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Box>
        <DragDropUpload
          processFile={handleFileUpload}
          acceptedFormats={acceptedFormats}
          loading={isUploading}
        />
      </Box>
    </Modal>
  );
};

export default AssetUploadModal;
