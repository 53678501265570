import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  CircularProgress,
  CardActionArea,
  Divider,
  Container,
  Skeleton,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import { useAuthInfo } from '@propelauth/react';
import { useNavigate } from 'react-router-dom';

const DashboardPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // Align items to the left
  borderRadius: '10px',
}));

export default function Home() {
  const { accessToken, orgHelper } = useAuthInfo();
  const [summaryData, setSummaryData] = useState({});
  const [recentActivity, setRecentActivity] = useState(new Array(10).fill(''));
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/products/summary`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Authorization header
            },
          }
        );
        setSummaryData(response.data.summary_data);
        setRecentActivity(response.data.recent_products);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };
    if (accessToken) {
      fetchData();
    }
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      {' '}
      {/* Use full width container */}
      <Typography variant="h4" gutterBottom>
        Home
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <CardActionArea onClick={() => navigate('/data')}>
            <DashboardPaper>
              <Typography variant="h5">Sources</Typography>
              <Typography variant="h6">
                {loading ? <Skeleton width={50} /> : summaryData.total_sources}
              </Typography>
            </DashboardPaper>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CardActionArea onClick={() => navigate('/products')}>
            <DashboardPaper>
              <Typography variant="h5">Products</Typography>
              <Typography variant="h6">
                {loading ? (
                  <Skeleton width={50} />
                ) : (
                  `${summaryData.total_products} (${summaryData.needs_review_count} need review)`
                )}
              </Typography>
            </DashboardPaper>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CardActionArea onClick={() => navigate('/attributes')}>
            <DashboardPaper>
              <Typography variant="h5">Attributes</Typography>
              <Typography variant="h6">
                {loading ? (
                  <Skeleton width={50} />
                ) : (
                  summaryData.total_attributes
                )}
              </Typography>
            </DashboardPaper>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CardActionArea onClick={() => navigate('/assets')}>
            <DashboardPaper>
              <Typography variant="h5">Assets</Typography>
              <Typography variant="h6">
                {loading ? <Skeleton width={50} /> : summaryData.total_assets}
              </Typography>
            </DashboardPaper>
          </CardActionArea>
        </Grid>
        {/* Recent Activity */}
        <Grid item xs={12}>
          <DashboardPaper>
            <Typography variant="h5" gutterBottom>
              Recent Activity
            </Typography>
            <List sx={{ width: '100%' }}>
              {recentActivity.map((activity, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <Divider />}
                  <ListItemButton
                    onClick={() => navigate(`/products/${activity.id}`)}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'action.hover',
                      },
                      transition: 'background-color 0.3s',
                      cursor: 'pointer',
                    }}
                  >
                    {loading ? (
                      <Skeleton width="100%" />
                    ) : (
                      <ListItemText
                        primary={
                          <Typography noWrap>
                            {`${new Date(
                              activity.updated_at
                            ).toLocaleString()} | ${activity.name} ${
                              activity.activity
                            }`}
                          </Typography>
                        }
                      />
                    )}
                  </ListItemButton>
                </React.Fragment>
              ))}
            </List>
          </DashboardPaper>
        </Grid>
      </Grid>
    </Box>
  );
}
