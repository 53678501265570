import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
} from '@mui/material';
import AgGridProductSelect from '../components/AgGridProductSelect';

const LinkProductsModal = ({
  open,
  handleClose,
  selectedAssets,
  onProductsLinked,
}) => {
  const [products, setProducts] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { accessToken, orgHelper } = useAuthInfo();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/products/get-names`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setProducts(response.data.products);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleLinkProducts = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/assets/link-products`,
        {
          asset_ids: selectedAssets,
          product_ids: selectedProducts,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      onProductsLinked();
      handleClose();
    } catch (error) {
      console.error('Error linking products:', error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Link Products to Assets</DialogTitle>
      <DialogContent>
        <AgGridProductSelect
          products={products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleLinkProducts} color="primary">
          Link Products
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkProductsModal;
