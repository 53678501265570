import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Typography } from '@mui/material';
import ManageSources from './ManageSources';
import CreateSourceFlow from './CreateSourceFlow';
import SourceDetails from './SourceDetails';

function Sources() {
  return (
    <div>
      <Routes>
        <Route index element={<ManageSources />} />
        <Route path="create" element={<CreateSourceFlow />} />
        <Route path=":sourceId" element={<SourceDetails />} />
      </Routes>
    </div>
  );
}

export default Sources;
