import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Grid,
  IconButton,
  CircularProgress,
  TextField,
  Typography,
  Skeleton,
  Tooltip,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Tab,
  Tabs,
  AppBar,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  FaMagic,
  FaTrash,
  FaInfoCircle,
  FaArrowLeft,
  FaSave,
  FaLayerGroup,
  FaFolder,
  FaCog,
} from 'react-icons/fa';
import { useAuthInfo } from '@propelauth/react';
import SchemaContext from '../../SchemaContext';
import PDPTooltip from '../../components/PDPTooltip';
import PercentageBarCellRenderer from '../../components/PercentageBarCellRenderer';
import ProductImageManager from './ProductImageManager';
import { triggerConfetti } from '../confettiEffect';
import ActivityTimeline from './ActivityTimeline';
import sampleActivityLog from '../sampleActivityLog';
import VariantManagement from './VariantManagement';
import AssetsList from './AssetsList';
import AttributeField from './AttributeField';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`product-tabpanel-${index}`}
      aria-labelledby={`product-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function ProductDetails() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { schema } = useContext(SchemaContext);
  const { accessToken, orgHelper } = useAuthInfo();
  const [productData, setProductData] = useState(null);
  const [shopifyLoading, setShopifyLoading] = useState(false);
  const [enrichAllLoading, setEnrichAllLoading] = useState(false);
  const [enrichLoadingFields, setEnrichLoadingFields] = useState({});
  const [anyFieldEdited, setAnyFieldEdited] = useState(false);
  const [qualityScore, setQualityScore] = useState(null);
  const [images, setImages] = useState([]);
  const [assets, setAssets] = useState([]);
  const [variantAttributes, setVariantAttributes] = useState([]);
  const [variants, setVariants] = useState([]);
  const [activityLog, setActivityLog] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [productSettings, setProductSettings] = useState({});
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [unsavedChangesConfirmationOpen, setUnsavedChangesConfirmationOpen] =
    useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const showSnackbar = useCallback((message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  }, []);

  useEffect(() => {
    // Fetch product details and quality score
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/products/get/${productId}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        setProductData(response.data.attributes);
        setAssets(response.data.assets);
        setProductSettings({
          'Created At': new Date(response.data.createdAt).toLocaleString(),
          'Updated At': new Date(response.data.updatedAt).toLocaleString(),
          Source: response.data.source,
        });
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    const fetchQualityScore = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/ai/quality-score/${productId}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        setQualityScore(response.data);
      } catch (error) {
        console.error('Error fetching quality score:', error);
      }
    };

    const fetchActivityLog = async () => {
      // In a real implementation, this would be an API call
      // For now, we're using the sample data
      setActivityLog(sampleActivityLog);
    };

    if (productId) {
      fetchProductDetails();
      fetchQualityScore();
      fetchActivityLog();
    }
  }, []);

  const handleSaveChanges = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/products/create-or-update`,
        { selectedProductID: productId, productData },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setAnyFieldEdited(false);
      showSnackbar('Changes saved successfully');
    } catch (error) {
      console.error('Error saving changes:', error);
      showSnackbar('Failed to save changes', 'error');
    }
  };

  const handleDeleteProduct = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/products/delete/${productId}`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      navigate('/products');
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleFieldChange = (field_name, value) => {
    setProductData({
      ...productData,
      [field_name]: {
        ...productData[field_name],
        value: value,
        status: 'MANUAL_OK',
        predictions: [],
      },
    });
    setAnyFieldEdited(true);
  };

  const handleEnrichAttribute = async (productId, attribute) => {
    setEnrichLoadingFields((prev) => ({ ...prev, [attribute]: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/ai/enrich`,
        { attributes: [attribute], products: [productId] },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      const updatedProductData =
        response.data.updated_products[productId][attribute];
      setProductData((prevData) => ({
        ...prevData,
        [attribute]: updatedProductData,
      }));
      showSnackbar(`Attribute enriched successfully!`);
    } catch (error) {
      console.error(error);
      showSnackbar('Failed to enrich attribute', 'error');
    } finally {
      setEnrichLoadingFields((prev) => ({ ...prev, [attribute]: false }));
      setAnyFieldEdited(true);
    }
  };

  // const handleEnrichAll = async () => {
  //   try {
  //     setEnrichAllLoading(true);
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/org/${orgHelper.getOrgs()[0].orgId}/ai/enrich`,
  //       { attributes: Object.keys(schema), products: [productId] },
  //       { headers: { Authorization: `Bearer ${accessToken}` } }
  //     );
  //     const updatedProductData = response.data.updated_products[productId];
  //     setProductData({ ...productData, ...updatedProductData });
  //     const imagesWithStringIds = response.data.new_images[productId].map(
  //       (img) => ({
  //         ...img,
  //         id: String(img.id), // Convert id to string
  //       })
  //     );
  //     setImages((prevImages) => [...prevImages, ...imagesWithStringIds]);
  //     showSnackbar('All fields enriched successfully');
  //     triggerConfetti();
  //   } catch (error) {
  //     console.error(error);
  //     showSnackbar('Failed to enrich all fields', 'error');
  //   } finally {
  //     setEnrichAllLoading(false);
  //     setAnyFieldEdited(true);
  //   }
  // };

  const handleEnrichAll = async () => {
    try {
      setEnrichAllLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/ai/scrape-and-enrich-product`,
        { productId },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setProductData({ ...productData, ...response.data.updatedAttributes });
      const imagesWithStringIds = response.data.newImages.map((img) => ({
        ...img,
        id: String(img.id), // Convert id to string
      }));
      setImages((prevImages) => [...prevImages, ...imagesWithStringIds]);
      // setVariantAttributes(response.data.updatedVariants.variant_attributes);
      // setVariants(response.data.updatedVariants.variants);
      showSnackbar('All fields enriched successfully');
      triggerConfetti();
    } catch (error) {
      console.error(error);
      showSnackbar('Failed to enrich all fields', 'error');
    } finally {
      setEnrichAllLoading(false);
      setAnyFieldEdited(true);
    }
  };

  const handleShopifySync = async () => {
    try {
      setShopifyLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/integrations/write-shopify-products`,
        {
          ids: [productId],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      console.log(response.data);
      showSnackbar('Synced with Shopify successfully!');
    } catch (error) {
      showSnackbar(
        'Failed to sync with Shopify, please try again later.',
        'error'
      );
      console.error('Error:', error);
    } finally {
      setShopifyLoading(false);
    }
  };

  if (!productData) {
    return <CircularProgress />;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        height: 'calc(100vh - 32px)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        py: 2,
      }}
    >
      <AppBar position='static' color='default' elevation={0}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          px={2}
          py={1}
        >
          <IconButton
            onClick={() =>
              anyFieldEdited
                ? setUnsavedChangesConfirmationOpen(true)
                : navigate('/products')
            }
            aria-label='go back'
            size='large'
          >
            <FaArrowLeft />
          </IconButton>
          <Typography variant='h5'>Product Details</Typography>
          <Box display='flex' alignItems='center'>
            <FormControl variant='outlined' sx={{ minWidth: 120, mr: 1 }}>
              <InputLabel id='product-status-label'>Status</InputLabel>
              <Select
                labelId='product-status-label'
                // value={productStatus}
                // onChange={handleStatusChange}
                label='Status'
              >
                <MenuItem value='draft'>Draft</MenuItem>
                <MenuItem value='active'>Active</MenuItem>
              </Select>
            </FormControl>
            <Tooltip
              title={
                orgHelper.getOrgs()[0].orgMetadata.demo
                  ? 'This feature is disabled in demo mode.'
                  : ''
              }
            >
              <span>
                <Button
                  onClick={handleEnrichAll}
                  startIcon={
                    enrichAllLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <FaMagic />
                    )
                  }
                  variant='contained'
                  disabled={
                    enrichAllLoading || orgHelper.getOrgs()[0].orgMetadata.demo
                  }
                  sx={{ mr: 1 }}
                >
                  Enrich All
                </Button>
              </span>
            </Tooltip>
            <Button
              variant='contained'
              color='primary'
              startIcon={<FaSave />}
              onClick={handleSaveChanges}
              disabled={!anyFieldEdited}
              sx={{ mr: 1 }}
            >
              Save Changes
            </Button>
            {orgHelper.getOrgs()[0].orgMetadata.SHOPIFY_KEY && (
              <Button
                startIcon={
                  shopifyLoading ? (
                    <CircularProgress size='1rem' />
                  ) : (
                    <img
                      src='/shopify_glyph.png'
                      alt='Shopify Logo'
                      style={{ width: '20px' }}
                    />
                  )
                }
                variant='contained'
                color='primary'
                onClick={handleShopifySync}
                disabled={shopifyLoading || anyFieldEdited}
              >
                {shopifyLoading ? 'Syncing...' : 'Sync to Shopify'}
              </Button>
            )}
          </Box>
        </Box>
      </AppBar>

      <Box sx={{ flexGrow: 1, display: 'flex', mt: 2 }}>
        <Box sx={{ width: '70%', pr: 2 }}>
          <Paper elevation={3} sx={{ p: 2, height: '100%', overflow: 'auto' }}>
            <Box display='flex' alignItems='center' mb={2}>
              <Tooltip
                title={
                  <PDPTooltip
                    attributeDefinition={''}
                    modelExplanation={
                      qualityScore?.explanation || 'No explanation available.'
                    }
                  />
                }
                arrow
              >
                <IconButton size='small'>
                  <FaInfoCircle fontSize='small' />
                </IconButton>
              </Tooltip>
              <Typography variant='body1' color='text.secondary' ml={1}>
                Quality:
              </Typography>
              <Box flexGrow={1} mx={1}>
                {qualityScore?.score !== undefined ? (
                  <PercentageBarCellRenderer value={qualityScore.score} />
                ) : (
                  <Skeleton width='100%' />
                )}
              </Box>
            </Box>
            <ProductImageManager
              productId={productId}
              images={images}
              setImages={setImages}
            />
            <Grid container spacing={2}>
              {Object.keys(schema)
                .sort((a, b) => {
                  if (schema[a].name.toLowerCase() === 'name') return -1;
                  if (schema[b].name.toLowerCase() === 'name') return 1;
                  if (schema[a].name.toLowerCase().includes('description'))
                    return -1;
                  if (schema[b].name.toLowerCase().includes('description'))
                    return 1;
                  if (schema[a].name.toLowerCase().includes('sku')) return -1;
                  if (schema[b].name.toLowerCase().includes('sku')) return 1;
                  return schema[a].name.localeCompare(schema[b].name);
                })
                .map((key) => {
                  return (
                    <AttributeField
                      key={key}
                      attributeId={key}
                      catalogAttributeData={schema[key]}
                      productAttributeData={productData[key]}
                      handleFieldChange={handleFieldChange}
                      isEnrichLoading={enrichLoadingFields[key]}
                      handleEnrichAttribute={() =>
                        handleEnrichAttribute(productId, key)
                      }
                    />
                  );
                })}
            </Grid>
          </Paper>
        </Box>

        <Box sx={{ width: '30%' }}>
          <Paper elevation={3} sx={{ height: '100%' }}>
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              aria-label='product tabs'
            >
              <Tab label='Variants' icon={<FaLayerGroup />} />
              <Tab label='Assets' icon={<FaFolder />} />
              <Tab label='Settings' icon={<FaCog />} />
              {/* <Tab label="Activity" icon={<FaHistory />} /> */}
            </Tabs>
            <Box sx={{ p: 2, height: 'calc(100% - 48px)', overflow: 'auto' }}>
              <TabPanel value={tabValue} index={0}>
                <VariantManagement
                  productId={productId}
                  variants={variants}
                  setVariants={setVariants}
                  variantAttributes={variantAttributes}
                  setVariantAttributes={setVariantAttributes}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <AssetsList
                  assets={assets}
                  setAssets={setAssets}
                  productId={productId}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {Object.entries(productSettings).map(([key, value]) => (
                  <TextField
                    key={key}
                    label={key}
                    value={value}
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    disabled
                    InputProps={{ readOnly: true }}
                  />
                ))}
                <Box mt={2}>
                  <Button
                    onClick={() => setDeleteConfirmationOpen(true)}
                    color='error'
                    startIcon={<FaTrash />}
                  >
                    Delete Product
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <ActivityTimeline activities={activityLog} />
              </TabPanel>
            </Box>
          </Paper>
        </Box>
      </Box>

      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>No</Button>
          <Button onClick={handleDeleteProduct} color='error'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={unsavedChangesConfirmationOpen}
        onClose={() => setUnsavedChangesConfirmationOpen(false)}
      >
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          You have unsaved changes. Are you sure you want to discard them?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUnsavedChangesConfirmationOpen(false)}>
            No
          </Button>
          <Button
            onClick={() => {
              setUnsavedChangesConfirmationOpen(false);
              navigate('/products');
            }}
            color='error'
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
