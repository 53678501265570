import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { FaMagic } from 'react-icons/fa';
import { AgGridReact } from 'ag-grid-react';
import SchemaContext from '../../SchemaContext';
import PercentageBarCellRenderer from '../../components/PercentageBarCellRenderer';

const EnrichData = ({
  dataMap,
  uploadedData,
  selectedRows,
  setSelectedRows,
}) => {
  const { schema } = useContext(SchemaContext);
  const [gridApi, setGridApi] = useState(null);

  const coverage = useMemo(() => {
    // Create a reverse mapping from schema name to header name
    const reverseDataMap = Object.entries(dataMap).reduce(
      (acc, [headerName, schemaName]) => {
        acc[schemaName] = headerName;
        return acc;
      },
      {}
    );

    // Initialize coverage object
    const coverage = Object.keys(schema).reduce((acc, schemaId) => {
      acc[schemaId] = 0;
      return acc;
    }, {});

    // Calculate coverage
    Object.entries(schema).forEach(
      ([schemaId, { name, enrichType, allowedValues }]) => {
        const headerName = reverseDataMap[name];
        if (headerName) {
          const validCount = uploadedData.reduce((count, row) => {
            const value = row[headerName];
            if (enrichType === 'CLASSIFY') {
              return count + (allowedValues.includes(value) ? 1 : 0);
            } else {
              return (
                count +
                (value !== null && value !== undefined && value !== '' ? 1 : 0)
              );
            }
          }, 0);
          coverage[schemaId] = validCount / uploadedData.length;
        }
      }
    );

    return coverage;
  }, [dataMap, uploadedData, schema]);
  const getEnrichType = (type) => {
    switch (type) {
      case 'GENERATE':
        return 'Generate';
      case 'CLASSIFY':
        return 'Classify';
      case 'EXTRACT':
        return 'Extract';
      case 'NONE':
        return '-';
      default:
        return type;
    }
  };

  const rowData = useMemo(() => {
    return Object.entries(schema).map(([key, value]) => ({
      id: key,
      name: value.name,
      type: getEnrichType(value.enrichType),
      coverage: coverage[key] || 0,
      isEnrichable: value.enrichType !== 'NONE' && coverage[key] < 1,
    }));
  }, [schema, coverage]);

  const updateSelectedRows = useCallback(() => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const newSelectedRows = selectedNodes.reduce((acc, node) => {
        acc[node.data.id] = true;
        return acc;
      }, {});
      setSelectedRows(newSelectedRows);
    }
  }, [gridApi]);

  // useEffect(() => {
  //   if (gridApi) {
  //     gridApi.forEachNode((node) => {
  //       if (node.data.isEnrichable) {
  //         node.setSelected(true);
  //       }
  //     });
  //     updateSelectedRows();
  //   }
  // }, [gridApi, rowData, updateSelectedRows]);

  const CheckboxCellRenderer = (params) => {
    const handleChange = () => {
      const newSelected = !params.node.isSelected();
      params.node.setSelected(newSelected);
      updateSelectedRows();
    };

    return (
      <Checkbox
        checked={params.node.isSelected()}
        disabled={!params.data.isEnrichable}
        onChange={handleChange}
      />
    );
  };
  const columnDefs = [
    {
      headerName: 'Enrich',
      field: 'isEnrichable',
      width: 150,
      cellRenderer: CheckboxCellRenderer,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Type',
      field: 'type',
      width: 120,
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Coverage',
      field: 'coverage',
      flex: 1,
      cellRenderer: PercentageBarCellRenderer,
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const isRowSelectable = (rowNode) => {
    return rowNode.data.isEnrichable;
  };

  const onSelectionChanged = () => {
    updateSelectedRows();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      <Typography variant="h6" gutterBottom>
        <FaMagic /> Select Columns to Enrich
      </Typography>
      <Typography variant="body1" color="primary" gutterBottom>
        Note: Feature disabled in demo mode
      </Typography>
      <Box sx={{ flexGrow: 1, width: '100%', height: '100%' }}>
        <div className="ag-theme-quartz" style={{ height: 500, width: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            isRowSelectable={isRowSelectable}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            pagination={true}
            paginationPageSize={20}
          />
        </div>
      </Box>
    </Box>
  );
};

export default EnrichData;
