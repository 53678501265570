import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useAuthInfo } from '@propelauth/react';
import SchemaContext from '../SchemaContext';
import axios from 'axios';
import { Typography, Paper, Button, Box, Skeleton, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import PercentageBarCellRenderer from '../components/PercentageBarCellRenderer';

const CoverageCellRenderer = (props) => {
  if (props.data.isCoverageLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
        width="100%"
      >
        <Box display="flex" alignItems="center" width="100%">
          <Box width="100%" mr={1}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={12}
              sx={{
                borderRadius: 5,
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Skeleton width={35} />
          </Box>
        </Box>
      </Box>
    );
  }
  return <PercentageBarCellRenderer {...props} />;
};

export default function ManageAttributes() {
  const navigate = useNavigate();
  const { accessToken, orgHelper } = useAuthInfo();
  const { schema } = useContext(SchemaContext);
  const [coverage, setCoverage] = useState({});
  const [isCoverageLoading, setIsCoverageLoading] = useState(true);

  useEffect(() => {
    const fetchCoverage = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/catalog/get-coverage`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setCoverage(response.data.coverage);
      } catch (error) {
        console.error('Error fetching attribute coverage:', error);
      } finally {
        setIsCoverageLoading(false);
      }
    };
    fetchCoverage();
  }, [accessToken, orgHelper]);

  const getEnrichType = (type) => {
    switch (type) {
      case 'GENERATE':
        return 'Generate';
      case 'CLASSIFY':
        return 'Classify';
      case 'EXTRACT':
        return 'Extract';
      case 'NONE':
        return '-';
      default:
        return type;
    }
  };

  const rowData = useMemo(() => {
    return Object.entries(schema).map(([key, value]) => ({
      id: key,
      name: value.name,
      type: getEnrichType(value.enrichType),
      allowedValues: value.allowedValues || [],
      coverage: coverage[key] || 0,
      isCoverageLoading,
    }));
  }, [schema, coverage, isCoverageLoading]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
  };

  const columnDefs = [
    { headerName: 'Attribute ID', field: 'id', hide: true },
    {
      headerName: 'Name',
      field: 'name',
      pinned: 'left',
      filter: true,
    },
    { headerName: 'Type', field: 'type', filter: true },
    { headerName: 'Allowed Values', field: 'allowedValues', hide: true },
    {
      headerName: 'Coverage',
      field: 'coverage',
      cellRenderer: CoverageCellRenderer,
      flex: 1,
      maxWidth: 400,
    },
  ];

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Attributes
      </Typography>
      <div>
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/attributes/create')}
          >
            + Create Attribute
          </Button>
        </Box>
        <Paper style={{ marginTop: '20px' }}>
          <div
            className="ag-theme-quartz"
            style={{ height: 400, width: '100%' }}
          >
            <AgGridReact
              rowData={rowData}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              domLayout="autoHeight"
              pagination={true}
              paginationPageSize={20}
              alwaysShowHorizontalScroll
              onRowClicked={(params) => {
                navigate(`/attributes/${params.data.id}`, {
                  state: params.data,
                });
              }}
              onFirstDataRendered={(params) => {
                params.api.autoSizeAllColumns();
              }}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}
