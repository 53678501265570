const sampleActivityLog = [
  {
    id: 'act_001',
    type: 'edit',
    action: 'Updated Product Name',
    description:
      "Changed product name from 'Nooni Makeup' to 'Nooni Beauty'",
    timestamp: '2024-07-31T14:30:00Z',
    user: 'John Doe',
    field: 'name',
  },
  {
    id: 'act_002',
    type: 'enrich',
    action: 'Enriched Product Description',
    description: 'AI-generated improved product description',
    timestamp: '2024-07-31T14:35:00Z',
    user: 'AI Assistant',
    field: 'description',
  },
  {
    id: 'act_003',
    type: 'upload',
    action: 'Added Product Image',
    description: 'Uploaded new product image: makeup_front_view.jpg',
    timestamp: '2024-07-31T15:00:00Z',
    user: 'Jane Smith',
    field: 'images',
  },
  {
    id: 'act_004',
    type: 'edit',
    action: 'Updated Price',
    description: 'Changed price from $199.99 to $189.99',
    timestamp: '2024-07-31T16:20:00Z',
    user: 'John Doe',
    field: 'price',
  },
  {
    id: 'act_005',
    type: 'enrich',
    action: 'Enriched Product Features',
    description: 'AI-generated additional product features',
    timestamp: '2024-08-01T09:15:00Z',
    user: 'AI Assistant',
    field: 'features',
  },
  {
    id: 'act_006',
    type: 'edit',
    action: 'Updated Stock Status',
    description: "Changed stock status from 'In Stock' to 'Low Stock'",
    timestamp: '2024-08-01T11:45:00Z',
    user: 'Jane Smith',
    field: 'stock_status',
  },
  {
    id: 'act_007',
    type: 'upload',
    action: 'Added Product Manual',
    description: 'Uploaded product manual: wireless_headphones_manual.pdf',
    timestamp: '2024-08-02T10:30:00Z',
    user: 'John Doe',
    field: 'assets',
  },
  {
    id: 'act_008',
    type: 'edit',
    action: 'Updated Product Categories',
    description: "Added category 'Noise Cancelling' to product categories",
    timestamp: '2024-08-02T14:00:00Z',
    user: 'Jane Smith',
    field: 'categories',
  },
  {
    id: 'act_009',
    type: 'enrich',
    action: 'Enriched SEO Keywords',
    description: 'AI-generated optimized SEO keywords',
    timestamp: '2024-08-03T09:00:00Z',
    user: 'AI Assistant',
    field: 'seo_keywords',
  },
  {
    id: 'act_010',
    type: 'edit',
    action: 'Updated Product Variants',
    description: "Added new color variant: 'Rose Gold'",
    timestamp: '2024-08-03T11:30:00Z',
    user: 'John Doe',
    field: 'variants',
  },
];

export default sampleActivityLog;
