import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';
import {
  navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Box,
  IconButton,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  FaArrowLeft,
  FaSyncAlt,
  FaTrash,
  FaCheckCircle,
  FaExclamationCircle,
  FaCircle,
} from 'react-icons/fa';

export default function SourceDetails() {
  const { accessToken, orgHelper } = useAuthInfo();
  const { sourceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(location.state || {});

  const [editableFields, setEditableFields] = useState({
    name: location.state?.name || '',
    schedule: location.state?.schedule || '',
  });

  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteProductsConfirmation, setDeleteProductsConfirmation] =
    useState('');

  const keyMapping = {
    id: 'Source ID',
    name: 'Name',
    type: 'Type',
    num_products: '# Products',
    schedule: 'Schedule',
    status: 'Status',
    created_at: 'Created At',
    last_run_at: 'Last Run At',
  };

  useEffect(() => {
    const fetchSourceDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/sources/get/${sourceId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Authorization header
            },
          }
        );
        setFields(response.data.source);
        setEditableFields({
          name: response.data.source.name,
          schedule: response.data.source.schedule,
        });
      } catch (err) {
        console.log('Error fetching source details:', err);
      } finally {
        setLoading(false);
      }
    };

    if (!location.state) {
      fetchSourceDetails();
    }
  }, [location.state, sourceId, accessToken, orgHelper]);

  const handleInputChange = (field, value) => {
    setEditableFields((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      console.log('Saving source details:', editableFields);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/sources/update/${sourceId}`,
        {
          name: editableFields.name,
          schedule: editableFields.schedule,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      setFields({
        ...fields,
        name: editableFields.name,
        schedule: editableFields.schedule,
      });
      navigate('/sources');
    } catch (err) {
      console.log('Error updating source details:', err);
    }
  };

  const handleDelete = async () => {
    try {
      if (deleteProductsConfirmation === 'delete products') {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/sources/delete/${sourceId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Authorization header
            },
          }
        );
      }
      setDeleteConfirmationOpen(false);
      navigate('/sources');
    } catch (err) {
      console.log('Error deleting source:', err);
    }
  };

  const StatusCellRenderer = (status) => {
    switch (status) {
      case 'RUNNING':
        return <CircularProgress size={20} />;
      case 'SUCCESS':
        return <FaCheckCircle style={{ color: 'green', fontSize: '20px' }} />;
      case 'ERROR':
        return (
          <FaExclamationCircle style={{ color: 'red', fontSize: '20px' }} />
        );
      case 'CREATED':
        return <FaCircle style={{ color: 'gray', fontSize: '16px' }} />;
      default:
        return status;
    }
  };

  const rowData = useMemo(() => {
    return Object.keys(fields)
      .filter((key) => key !== 'created_at' && key !== 'last_run_at')
      .map((key) => ({
        key: key,
        value: fields[key],
      }));
  }, [fields]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box mb={2} display="flex" alignItems="center">
        <IconButton
          onClick={() => navigate('/sources')} // Adjust the path as needed
          aria-label="go back"
          size="large"
        >
          <FaArrowLeft />
        </IconButton>
        <Typography variant="h4">Source Details</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-start" mb={3}>
        <Button onClick={() => setDeleteConfirmationOpen(true)} color="error">
          <FaTrash style={{ marginRight: '10px' }} />
          <Typography variant="body1">Delete Source</Typography>
        </Button>
      </Box>
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to delete this source and associated products?
          </Typography>
          <Typography gutterBottom>
            This action is irreversible. To confirm, type "delete products"
            below:
          </Typography>
          <TextField
            fullWidth
            value={deleteProductsConfirmation}
            onChange={(e) => setDeleteProductsConfirmation(e.target.value)}
            margin="normal"
            placeholder="DANGEROUS: Type 'delete products' to confirm"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={deleteProductsConfirmation !== 'delete products'}
            onClick={handleDelete}
            color="error"
          >
            Delete Source
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer
        component={Paper}
        variant="outlined"
        style={{ maxWidth: '80%' }}
      >
        <Table aria-label="key value table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Source Detail</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Value</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{keyMapping[item.key] || item.key}</TableCell>
                <TableCell>
                  {item.key === 'name' ? (
                    <TextField
                      value={editableFields.name}
                      onChange={(e) =>
                        handleInputChange('name', e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                    />
                  ) : item.key === 'schedule' ? (
                    <FormControl component="fieldset" fullWidth>
                      <Select
                        labelId="schedule"
                        value={editableFields.schedule}
                        onChange={(e) =>
                          handleInputChange('schedule', e.target.value)
                        }
                        label="Schedule"
                        style={{ height: '56px' }} // Match TextField height
                      >
                        <MenuItem value="MANUAL">One-Off</MenuItem>
                        <MenuItem value="HOURLY" disabled>
                          Hourly
                        </MenuItem>
                        <MenuItem value="DAILY" disabled>
                          Daily
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : item.key === 'status' ? (
                    StatusCellRenderer(item.value)
                  ) : (
                    item.value
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // p={2}
        mt={2}
      >
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Changes
        </Button>
        {/* <Button
          variant="contained"
          startIcon={<FaSyncAlt style={{ marginRight: '6px' }} />}
        >
          Refresh Data
        </Button> */}
      </Box>
    </>
  );
}
