import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuthInfo } from '@propelauth/react';
import SchemaContext from '../SchemaContext';
import {
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
  Grid,
  Autocomplete,
} from '@mui/material';

const Onboarding = () => {
  const navigate = useNavigate();
  const { accessToken, orgHelper } = useAuthInfo();
  const { schema, setSchema } = useContext(SchemaContext);
  const defaultOptions = [
    'SKU',
    'Name',
    'Brand',
    'Description',
    'Price',
    'Category',
    'URL',
    'Stock',
    'Color',
    'Dimensions',
    'Weight',
    'Designer',
    'Shape',
    'Material',
    'Style',
    'Model Year',
    'Condition',
    'Mileage',
    'Warranty',
    'Allergens',
  ];

  const [selectedOptions, setSelectedOptions] = useState([
    'SKU',
    'Name',
    'Description',
    'Category',
    'URL',
  ]);
  const [customField, setCustomField] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (event, value) => {
    setSelectedOptions(value);
  };

  const handleCustomFieldChange = (event) => {
    setCustomField(event.target.value);
  };

  const handleAddCustomField = () => {
    if (customField.trim() !== '') {
      setSelectedOptions((prevSelected) => [...prevSelected, customField]);
      setCustomField('');
    }
  };

  const saveSchema = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/catalog/create-schema`,
        {
          schema: selectedOptions,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      setSchema(response.data.schema);
      navigate('/home');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    await saveSchema();
    setLoading(false);
  };

  if (Object.keys(schema).length > 0) {
    navigate('/home');
  }

  return (
    <Container
      maxWidth="lg"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img
        src="/angler_white.png"
        alt="logo"
        style={{
          marginTop: '10px', // Adjust this value as needed
          marginBottom: '20px',
          alignSelf: 'center',
          maxWidth: '100%',
          width: 'auto',
          height: 'auto',
          maxHeight: '100px', // Adjust this value as needed
        }}
      />
      <Typography variant="h5" gutterBottom mt={4}>
        Welcome! Please select fields for your product catalog:
      </Typography>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          options={defaultOptions}
          value={selectedOptions}
          onChange={handleSelectChange}
          renderInput={(params) => (
            <TextField {...params} label="Select Fields" variant="outlined" />
          )}
        />
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: '20px' }}
        >
          <Grid item xs={6}>
            <TextField
              label="Custom Attribute"
              fullWidth
              value={customField}
              onChange={handleCustomFieldChange}
              variant="outlined"
              sx={{ minHeight: '20px' }} // Adjust the height as needed
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleAddCustomField}>
              + Add Custom Attribute
            </Button>
          </Grid>
        </Grid>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={selectedOptions.length === 0 || loading}
        style={{ marginTop: '20px' }}
      >
        {loading ? 'Saving...' : 'Create Schema'}
      </Button>
    </Container>
  );
};

export default Onboarding;
