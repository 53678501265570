// src/SchemaContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useAuthInfo } from '@propelauth/react';
import axios from 'axios';

const SchemaContext = createContext();

export const SchemaProvider = ({ children }) => {
  const { isLoggedIn, accessToken, orgHelper } = useAuthInfo();
  const [schemaLoading, setLoading] = useState(true);
  const [schema, setSchema] = useState({});

  useEffect(() => {
    const getSchema = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/catalog/get-schema`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setSchema(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (isLoggedIn) {
      getSchema();
    }
  }, [isLoggedIn]);

  return (
    <SchemaContext.Provider
      value={{
        schemaLoading,
        schema,
        setSchema,
      }}
    >
      {children}
    </SchemaContext.Provider>
  );
};

export default SchemaContext;
