import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
  Badge,
} from '@mui/material';
import {
  FaUpload,
  FaShoppingBag,
  FaList,
  FaFileExport,
  FaHome,
  FaCloud,
  FaCog,
  FaSignOutAlt,
  FaMagic,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import {
  useLogoutFunction,
  useAuthInfo,
  useRedirectFunctions,
} from '@propelauth/react';

const navItems = [
  { to: '/home', icon: FaHome, text: 'Home' },
  { to: '/sources', icon: FaUpload, text: 'Sources' },
  { to: '/products', icon: FaShoppingBag, text: 'Products' },
  { to: '/attributes', icon: FaList, text: 'Attributes' },
  { to: '/channels', icon: FaFileExport, text: 'Channels' },
  { to: '/assets', icon: FaCloud, text: 'Assets' },
  // { to: '/assistant', icon: FaMagic, text: 'Assistant' },
];

function NavBar() {
  const { user, orgHelper } = useAuthInfo();
  const logoutFunction = useLogoutFunction();
  const { redirectToAccountPage } = useRedirectFunctions();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    handleClose();
    logoutFunction(true);
  };
  const handleAccountSettings = () => {
    handleClose();
    redirectToAccountPage({ redirectBackToUrl: window.location.href });
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const isDemo = orgHelper.getOrgs()[0]?.orgMetadata.demo;

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: isOpen ? 240 : 60,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isOpen ? 240 : 60,
          overflowX: 'hidden',
          transition: 'width 0.3s',
          boxSizing: 'border-box',
        },
      }}
    >
      <Box
        sx={{
          p: isOpen ? 2 : 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          {isOpen ? (
            <Link to='/home'>
              <img
                src='/AngleraLogo.svg'
                alt='logo'
                style={{
                  marginTop: '10px',
                  marginBottom: '20px',
                  paddingTop: '20px',
                  maxWidth: '100%',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </Link>
          ) : (
            <Box sx={{ height: '42px' }} /> // Placeholder to maintain consistent height
          )}
          <IconButton onClick={toggleDrawer} sx={{ mr: isOpen ? -1 : 0 }}>
            {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
          </IconButton>
        </Box>
        <List>
          {navItems.map((item) => (
            <React.Fragment key={item.to}>
              <Divider />
              <ListItem
                button
                component={Link}
                to={item.to}
                sx={{ height: '45px' }}
                selected={location.pathname.startsWith(item.to)}
              >
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <item.icon />
                </ListItemIcon>
                {isOpen && <ListItemText primary={item.text} />}
              </ListItem>
            </React.Fragment>
          ))}
          <Divider />
        </List>
        <Box sx={{ mt: 'auto', mb: 2, textAlign: 'center' }}>
          <Divider mb={2} />
          {isOpen && (
            <>
              <Typography variant='body1'>
                {user?.firstName} {user?.lastName}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {orgHelper.getOrgs()[0]?.orgName}
              </Typography>
              {isDemo && (
                <Tooltip title='Demo Mode -- some features are limited.' arrow>
                  <Badge color='secondary' badgeContent='Demo' sx={{ mt: 1 }}>
                    <Box />
                  </Badge>
                </Tooltip>
              )}
            </>
          )}
          <IconButton onClick={handleMenu}>
            <Avatar
              alt={user?.email}
              src={user?.pictureUrl}
              sx={{ width: isOpen ? 40 : 32, height: isOpen ? 40 : 32 }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <MenuItem onClick={handleAccountSettings}>
              <ListItemIcon>
                <FaCog fontSize='small' />
              </ListItemIcon>
              <ListItemText>Account Settings</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <FaSignOutAlt fontSize='small' />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Drawer>
  );
}

export default NavBar;
// import React, { useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import {
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Avatar,
//   IconButton,
//   Typography,
//   Box,
//   Menu,
//   MenuItem,
//   Divider,
//   Tooltip,
//   Badge,
// } from '@mui/material';
// import {
//   FaUpload,
//   FaShoppingBag,
//   FaList,
//   FaFileExport,
//   FaHome,
//   FaCloud,
//   FaCog,
//   FaSignOutAlt,
//   FaMagic,
// } from 'react-icons/fa';
// import {
//   useLogoutFunction,
//   useAuthInfo,
//   useRedirectFunctions,
// } from '@propelauth/react';

// const navItems = [
//   { to: '/home', icon: FaHome, text: 'Home' },
//   { to: '/sources', icon: FaUpload, text: 'Sources' },
//   { to: '/products', icon: FaShoppingBag, text: 'Products' },
//   { to: '/attributes', icon: FaList, text: 'Attributes' },
//   { to: '/channels', icon: FaFileExport, text: 'Channels' },
//   { to: '/assets', icon: FaCloud, text: 'Assets' },
//   { to: '/assistant', icon: FaMagic, text: 'Assistant' },
// ];

// function NavBar() {
//   const { user, orgHelper } = useAuthInfo();
//   const logoutFunction = useLogoutFunction();
//   const { redirectToAccountPage } = useRedirectFunctions();
//   const location = useLocation();
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleMenu = (event) => setAnchorEl(event.currentTarget);
//   const handleClose = () => setAnchorEl(null);
//   const handleLogout = () => {
//     handleClose();
//     logoutFunction(false);
//     window.location.href = 'https://www.anglercloud.com';
//   };
//   const handleAccountSettings = () => {
//     handleClose();
//     redirectToAccountPage();
//   };

//   const isDemo = orgHelper.getOrgs()[0]?.orgMetadata.demo;

//   return (
//     <Drawer
//       variant="permanent"
//       sx={{
//         width: 240,
//         flexShrink: 0,
//         '& .MuiDrawer-paper': {
//           width: 240,
//           boxSizing: 'border-box',
//         },
//       }}
//     >
//       <Box
//         sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
//       >
//         <Box sx={{ display: 'flex', justifyContent: 'center' }}>
//           <Link to="/home">
//             <img
//               src="/AngleraLogo.svg"
//               alt="logo"
//               style={{
//                 marginTop: '10px',
//                 marginBottom: '20px',
//                 alignSelf: 'center',
//                 maxWidth: '100%',
//                 width: 'auto',
//                 height: 'auto',
//                 maxHeight: '42px',
//               }}
//             />
//           </Link>
//         </Box>
//         <List>
//           {navItems.map((item) => (
//             <React.Fragment key={item.to}>
//               <Divider />
//               <ListItem
//                 button
//                 component={Link}
//                 to={item.to}
//                 sx={{ height: '45px' }}
//                 selected={location.pathname.startsWith(item.to)}
//               >
//                 <ListItemIcon sx={{ minWidth: '35px' }}>
//                   <item.icon />
//                 </ListItemIcon>
//                 <ListItemText primary={item.text} />
//               </ListItem>
//             </React.Fragment>
//           ))}
//           <Divider />
//         </List>
//         <Box sx={{ mt: 'auto', mb: 2, textAlign: 'center' }}>
//           <Divider mb={2} />
//           <Typography variant="body1">
//             {user?.firstName} {user?.lastName}
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             {orgHelper.getOrgs()[0]?.orgName}
//           </Typography>
//           {isDemo && (
//             <Tooltip title="Demo Mode -- some features are limited." arrow>
//               <Badge color="secondary" badgeContent="Demo" sx={{ mt: 1 }}>
//                 <Box />
//               </Badge>
//             </Tooltip>
//           )}
//           <IconButton onClick={handleMenu}>
//             <Avatar alt={user?.email} src={user?.pictureUrl} />
//           </IconButton>
//           <Menu
//             anchorEl={anchorEl}
//             open={Boolean(anchorEl)}
//             onClose={handleClose}
//             anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//             transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//           >
//             <MenuItem onClick={handleAccountSettings}>
//               <ListItemIcon>
//                 <FaCog fontSize="small" />
//               </ListItemIcon>
//               <ListItemText>Account Settings</ListItemText>
//             </MenuItem>
//             <MenuItem onClick={handleLogout}>
//               <ListItemIcon>
//                 <FaSignOutAlt fontSize="small" />
//               </ListItemIcon>
//               <ListItemText>Logout</ListItemText>
//             </MenuItem>
//           </Menu>
//         </Box>
//       </Box>
//     </Drawer>
//   );
// }

// export default NavBar;
