import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography, Box } from '@mui/material';
import { FaPencilAlt, FaMagic, FaUpload, FaTrash } from 'react-icons/fa';

const getActivityIcon = (activityType) => {
  switch (activityType) {
    case 'edit':
      return <FaPencilAlt />;
    case 'enrich':
      return <FaMagic />;
    case 'upload':
      return <FaUpload />;
    case 'delete':
      return <FaTrash />;
    default:
      return null;
  }
};

const ActivityTimeline = ({ activities }) => {
  return (
    <Timeline
      sx={{
        [`& .MuiTimelineItem-root::before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {activities.map((activity, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot color="primary">
              {getActivityIcon(activity.type)}
            </TimelineDot>
            {index !== activities.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="body1" component="span">
              {activity.action}
            </Typography>
            <Typography variant= "body2" color="text.secondary">{activity.description}</Typography>
            <Box mt={1}>
              <Typography variant="caption" color="text.secondary">
                {new Date(activity.timestamp).toLocaleString()}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ ml: 2 }}
              >
                By: {activity.user}
              </Typography>
            </Box>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default ActivityTimeline;
