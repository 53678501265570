import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Paper,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { FaPaperPlane, FaRobot, FaUser } from 'react-icons/fa';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';

function Assistant() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const { accessToken, orgHelper } = useAuthInfo();

  useEffect(() => {
    // Add the initial hello message when the component mounts
    setMessages([
      {
        text: "I'm your personal catalog expert. How can I help?",
        sender: 'assistant',
      },
    ]);
    fetchProducts();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    const lastMessage = document.getElementById('last-message');
    if (lastMessage) {
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/products/get-names`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setProducts(response.data.products);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleSend = async () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: 'user' }]);
      setInput('');
      if (!selectedProduct) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: 'Please select a product.', sender: 'assistant' },
        ]);
        return;
      }
      setIsLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/ai/assistant`,
          {
            message: input,
            productId: selectedProduct,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: response.data.message, sender: 'assistant' },
        ]);
      } catch (error) {
        console.error('Error sending message:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: 'Sorry, there was an error processing your message.',
            sender: 'assistant',
          },
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const LoadingIcon = () => (
    <ListItem alignItems="flex-start">
      <Avatar sx={{ bgcolor: 'secondary.main', mr: 1 }}>
        <FaRobot />
      </Avatar>
      <Paper elevation={1} sx={{ p: 1 }}>
        <Typography>Typing...</Typography>
      </Paper>
    </ListItem>
  );

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Assistant
      </Typography>
      <Container>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel id="product-select-label">Select a Product</InputLabel>
          <Select
            labelId="product-select-label"
            value={selectedProduct}
            label="Select a Product"
            onChange={(e) => setSelectedProduct(e.target.value)}
          >
            {products.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Paper
          elevation={3}
          sx={{
            height: '60vh',
            mt: 3,
            mb: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <List sx={{ flexGrow: 1, overflow: 'auto' }}>
            {messages.map((message, index) => (
              <ListItem
                key={index}
                alignItems="flex-start"
                sx={{
                  flexDirection:
                    message.sender === 'user' ? 'row-reverse' : 'row',
                }}
                // Add the last-message id to the last message
                id={index === messages.length - 1 ? 'last-message' : undefined}
              >
                <Avatar
                  sx={{
                    bgcolor:
                      message.sender === 'user'
                        ? 'primary.main'
                        : 'secondary.main',
                    mr: message.sender === 'user' ? 0 : 1,
                    ml: message.sender === 'user' ? 1 : 0,
                  }}
                >
                  {message.sender === 'user' ? <FaUser /> : <FaRobot />}
                </Avatar>
                <Paper elevation={1} sx={{ p: 1 }}>
                  <ListItemText primary={message.text} />
                </Paper>
              </ListItem>
            ))}
            {isLoading && <LoadingIcon />}
          </List>
        </Paper>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message here..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          />
          <Button
            variant="contained"
            color="primary"
            endIcon={<FaPaperPlane />}
            onClick={handleSend}
            disabled={isLoading}
            sx={{ ml: 1, height: '56px' }}
          >
            Send
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default Assistant;
