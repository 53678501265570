import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  CircularProgress,
  Typography,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { FaUnlink, FaTrash, FaImage } from 'react-icons/fa';
import { useAuthInfo } from '@propelauth/react';
import AssetUploadModal from '../../components/AssetUploadModal';
import AssetViewer from '../../components/AssetViewer';

const ProductImageManager = ({ productId, images, setImages }) => {
  const { accessToken, orgHelper } = useAuthInfo();
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [selectedImageForViewer, setSelectedImageForViewer] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImageAssets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/products/images/${productId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Authorization header
            },
          }
        );
        const imagesWithStringIds = response.data.images.map((img) => ({
          ...img,
          id: String(img.id), // Convert id to string
        }));
        setImages(imagesWithStringIds);
      } catch (error) {
        setError(error);
        console.error('Error fetching image assets:', error);
      } finally {
        setLoading(false);
      }
    };

    if (productId) {
      fetchImageAssets();
    }
  }, []);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const showSnackbar = useCallback((message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const reorderProductImages = async (items) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/products/reorder-images/${productId}`,
        {
          new_order: items.map((item) => item.id),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header
          },
        }
      );
      showSnackbar('Images reordered successfully', 'success');
    } catch (error) {
      console.error('Error reordering product images:', error);
      showSnackbar('Failed to reorder images', 'error');
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setImages(items);
    reorderProductImages(items);
  };

  const handleSelectImage = (event, imageId) => {
    event.stopPropagation();
    setSelectedImages((prev) =>
      prev.includes(imageId)
        ? prev.filter((id) => id !== imageId)
        : [...prev, imageId]
    );
  };

  const handleDeleteSelected = () => {
    const multiDelete = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/assets/delete-multiple`,
          { assetIds: selectedImages.map((imageId) => Number(imageId)) },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setImages((prev) =>
          prev.filter((image) => !selectedImages.includes(image.id))
        );
        setSelectedImages([]);
        showSnackbar('Image(s) deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting multiple assets:', error);
        showSnackbar('Failed to delete image(s)', 'error');
      }
    };
    multiDelete();
  };

  const handleUnlinkSelected = () => {
    const multiUnlink = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/org/${
            orgHelper.getOrgs()[0].orgId
          }/assets/unlink-multiple`,
          {
            productId,
            assetIds: selectedImages.map((imageId) => Number(imageId)),
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setImages((prev) =>
          prev.filter((image) => !selectedImages.includes(image.id))
        );
        setSelectedImages([]);
        showSnackbar('Image(s) unlinked successfully', 'success');
      } catch (error) {
        console.error('Error unlinking multiple assets:', error);
        showSnackbar('Failed to unlink image(s)', 'error');
      }
    };
    multiUnlink();
  };

  const handleDeleteSingle = async (id) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/assets/delete/${Number(id)}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setImages((prev) => prev.filter((image) => id !== image.id));
      handleCloseViewer();
      showSnackbar('Image deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting asset:', error);
      showSnackbar('Failed to delete image', 'error');
    }
  };

  const handleAddImage = (uploadedImage) => {
    setImages((prev) => [
      ...prev,
      { ...uploadedImage, id: String(uploadedImage.id) },
    ]);
    showSnackbar('Added new image', 'success');
  };

  const handleImageClick = (event, image) => {
    if (!event.defaultPrevented) {
      event.preventDefault();
      setSelectedImageForViewer(image);
      setViewerOpen(true);
    }
  };

  const handleCloseViewer = () => {
    setViewerOpen(false);
    setSelectedImageForViewer(null);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  if (images.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '300px',
          border: '2px dashed #ccc',
          borderRadius: '8px',
          padding: '20px',
          textAlign: 'center',
        }}
      >
        <FaImage size={'medium'} sx={{ mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          No Images Available
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Add some images to get started with your product gallery.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          // startIcon={<FaPlus />}
          onClick={() => setIsAddImageModalOpen(true)}
        >
          + Add Image
        </Button>
        <AssetUploadModal
          open={isAddImageModalOpen}
          onClose={() => setIsAddImageModalOpen(false)}
          acceptedFormats={['jpg', 'jpeg', 'png']}
          onAssetUploaded={handleAddImage}
          linkedProductId={productId}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsAddImageModalOpen(true)}
        >
          + Add Image
        </Button>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FaUnlink />}
            onClick={handleUnlinkSelected}
            disabled={selectedImages.length === 0}
            sx={{ mr: 1 }}
          >
            Unlink ({selectedImages.length})
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<FaTrash />}
            onClick={handleDeleteSelected}
            disabled={selectedImages.length === 0}
          >
            Delete ({selectedImages.length})
          </Button>
        </Box>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="images" direction="horizontal">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                gap: 2,
                padding: 2,
                border: '1px solid #ccc',
              }}
            >
              {images.map((image, index) => (
                <Draggable key={image.id} draggableId={image.id} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        position: 'relative',
                        aspectRatio: '1 / 1',
                        width: '100%',
                        zIndex: snapshot.isDragging ? 1000 : 1,
                        cursor: snapshot.isDragging ? 'grabbing' : 'grab',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: 3,
                        },
                      }}
                      onClick={(event) => handleImageClick(event, image)}
                    >
                      <Card
                        sx={{
                          width: '100%',
                          height: '100%',
                          border: index === 0 ? '2px solid gold' : 'none',
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={image.url}
                          alt={`Product image ${index + 1}`}
                          sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                        <Checkbox
                          checked={selectedImages.includes(image.id)}
                          onChange={(event) =>
                            handleSelectImage(event, image.id)
                          }
                          onClick={(event) => event.stopPropagation()}
                          sx={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'primary.main',
                            },
                            background: 'rgba(255, 255, 255, 0.3)',
                            borderRadius: '50%',
                          }}
                        />
                      </Card>
                      {index === 0 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 5,
                            left: 5,
                            color: 'gold',
                            fontSize: '24px',
                            zIndex: 10,
                            pointerEvents: 'none',
                          }}
                        >
                          ★
                        </Box>
                      )}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <AssetUploadModal
        open={isAddImageModalOpen}
        onClose={() => setIsAddImageModalOpen(false)}
        acceptedFormats={['jpg', 'jpeg', 'png']}
        onAssetUploaded={handleAddImage}
        linkedProductId={productId}
      />
      <AssetViewer
        open={viewerOpen}
        onClose={handleCloseViewer}
        asset={selectedImageForViewer}
        onDelete={handleDeleteSingle}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProductImageManager;
// import React, { useState, useEffect } from 'react';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import {
//   Box,
//   Card,
//   CardMedia,
//   Checkbox,
//   IconButton,
//   CircularProgress,
//   Typography,
// } from '@mui/material';
// import { FaPlus, FaTrash } from 'react-icons/fa';

// const ProductImageManager = ({ productId }) => {
//   const [images, setImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // Simulated API call
//     setImages([
//       {
//         id: 1,
//         url: 'https://i.pinimg.com/474x/7f/80/47/7f8047283f1a5bb1a1365390648d0784.jpg',
//       },
//       {
//         id: 2,
//         url: 'https://images.thdstatic.com/productImages/72b9aa15-5112-4dff-9639-b68170e00530/svn/house-numbers-3582b-b-64_600.jpg',
//       },
//       {
//         id: 3,
//         url: 'https://i.pinimg.com/736x/96/5a/ec/965aecc908fb8b7c6b84421e6dd1cfa7.jpg',
//       },
//       {
//         id: 4,
//         url: 'https://i.pinimg.com/736x/50/f2/26/50f2261e4e3a97bf5a5f58af5ec2f845.jpg',
//       },
//       {
//         id: 1,
//         url: 'https://i.pinimg.com/474x/7f/80/47/7f8047283f1a5bb1a1365390648d0784.jpg',
//       },
//       {
//         id: 2,
//         url: 'https://images.thdstatic.com/productImages/72b9aa15-5112-4dff-9639-b68170e00530/svn/house-numbers-3582b-b-64_600.jpg',
//       },
//       {
//         id: 3,
//         url: 'https://i.pinimg.com/736x/96/5a/ec/965aecc908fb8b7c6b84421e6dd1cfa7.jpg',
//       },
//       {
//         id: 4,
//         url: 'https://i.pinimg.com/736x/50/f2/26/50f2261e4e3a97bf5a5f58af5ec2f845.jpg',
//       },
//     ]);
//     setLoading(false);
//   }, [productId]);

//   const onDragEnd = (result) => {
//     if (!result.destination) return;

//     const items = Array.from(images);
//     const [reorderedItem] = items.splice(result.source.index, 1);
//     items.splice(result.destination.index, 0, reorderedItem);

//     setImages(items);
//     // Call API to update image order
//     // updateImageOrder(items);
//   };

//   const handleImageSelect = (imageId, event) => {
//     event.stopPropagation();
//     setSelectedImages((prev) =>
//       prev.includes(imageId)
//         ? prev.filter((id) => id !== imageId)
//         : [...prev, imageId]
//     );
//   };

//   const handleDeleteSelected = () => {
//     // Call API to delete selected images
//     // deleteImages(selectedImages);
//     setImages(images.filter((img) => !selectedImages.includes(img.id)));
//     setSelectedImages([]);
//   };

//   const handleAddImage = () => {
//     // Open file dialog or implement your image upload logic
//     console.log('Add image functionality to be implemented');
//   };

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (error) {
//     return <Typography color="error">{error}</Typography>;
//   }

//   return (
//     <Box>
//       <DragDropContext onDragEnd={onDragEnd}>
//         <Droppable droppableId="images" direction="horizontal">
//           {(provided) => (
//             <Box
//               {...provided.droppableProps}
//               ref={provided.innerRef}
//               sx={{
//                 display: 'flex',
//                 flexWrap: 'wrap',
//                 gap: 2,
//               }}
//             >
//               {images.map((image, index) => (
//                 <Draggable
//                   key={image.id}
//                   draggableId={image.id.toString()}
//                   index={index}
//                 >
//                   {(provided, snapshot) => (
//                     <Box
//                       ref={provided.innerRef}
//                       {...provided.draggableProps}
//                       {...provided.dragHandleProps}
//                       sx={{
//                         width: index === 0 ? '100%' : 'calc(33.33% - 16px)',
//                         flexGrow: index === 0 ? 1 : 0,
//                         opacity: snapshot.isDragging ? 0.5 : 1,
//                         transition: 'all 0.2s',
//                       }}
//                     >
//                       <Card
//                         sx={{
//                           position: 'relative',
//                           paddingTop: '100%', // 1:1 Aspect Ratio
//                           cursor: 'move',
//                         }}
//                       >
//                         <CardMedia
//                           component="img"
//                           image={image.url}
//                           alt={`Product image ${index + 1}`}
//                           sx={{
//                             position: 'absolute',
//                             top: 0,
//                             left: 0,
//                             width: '100%',
//                             height: '100%',
//                             objectFit: 'cover',
//                           }}
//                         />
//                         <Box
//                           position="absolute"
//                           top={0}
//                           left={0}
//                           p={1}
//                           bgcolor="rgba(255, 255, 255, 0.7)"
//                         >
//                           <Checkbox
//                             checked={selectedImages.includes(image.id)}
//                             onChange={(event) =>
//                               handleImageSelect(image.id, event)
//                             }
//                           />
//                         </Box>
//                       </Card>
//                     </Box>
//                   )}
//                 </Draggable>
//               ))}
//               {provided.placeholder}
//             </Box>
//           )}
//         </Droppable>
//       </DragDropContext>
//       <Box mt={2} display="flex" justifyContent="space-between">
//         <IconButton onClick={handleAddImage} color="primary">
//           <FaPlus />
//         </IconButton>
//         <IconButton
//           onClick={handleDeleteSelected}
//           color="secondary"
//           disabled={selectedImages.length === 0}
//         >
//           <FaTrash />
//         </IconButton>
//       </Box>
//     </Box>
//   );
// };

// export default ProductImageManager;
