import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const PrettifiedJSONViewer = ({ feedUrl }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const urlRegex =
      /(http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    const isValidUrl = (url) => url.match(urlRegex) !== null;
    const fetchData = async () => {
      try {
        const apiUrl =
          process.env.NODE_ENV === 'development' &&
          feedUrl.includes('localhost')
            ? feedUrl.replace(/^.*\/\/[^\/]+/, '')
            : feedUrl;
        const response = await axios.get(apiUrl);
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (isValidUrl(feedUrl)) {
      fetchData();
    } else {
      setLoading(false);
      setError(new Error('Invalid URL'));
    }
  }, [feedUrl]);

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <pre
      style={{
        backgroundColor: '#f5f5f5',
        padding: '1em',
        borderRadius: '8px',
        border: '1px solid #ccc',
        whiteSpace: 'pre-wrap', // Ensure text wraps when it exceeds the container width
        wordWrap: 'break-word', // Break long words if needed
      }}
    >
      {JSON.stringify(data, null, 2)}
    </pre>
  );
};

export default PrettifiedJSONViewer;
