import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import { FaMagic } from 'react-icons/fa';
export default function ReviewAmbiguousOutput({
  attributeId,
  fieldName,
  editedFieldData,
  handleFieldChange,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={fieldName === 'Description' ? 'column' : 'row'}
    >
      {editedFieldData.predictions?.length > 0 && (
        <Box
          display="flex"
          alignItems="center"
          mt={fieldName === 'Description' ? 1 : 0}
        >
          <FaMagic style={{ marginRight: '10px' }} />
          <Typography style={{ marginRight: '16px', fontWeight: 'bold' }}>
            Suggested Changes:
          </Typography>
        </Box>
      )}
      {editedFieldData.predictions.map((label, index) => {
        if (fieldName === 'Description') {
          return (
            <Box key={index}>
              <Box display="flex" flexWrap="wrap" justifyContent="center">
                <Card
                  key={index}
                  style={{
                    margin: '10px',
                    maxWidth: '500px',
                  }}
                >
                  <CardActionArea
                    onClick={() => handleFieldChange(attributeId, label)}
                  >
                    <CardContent>
                      <Typography variant="body2">{label}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            </Box>
          );
        }
        return (
          <Button
            key={index}
            variant="contained"
            style={{ margin: '5px' }}
            onClick={() => handleFieldChange(attributeId, label)}
          >
            {label}
          </Button>
        );
      })}
      {editedFieldData.predictions?.length > 0 && (
        <Button
          variant="contained"
          color="error"
          style={{ margin: '5px' }}
          onClick={() => handleFieldChange(attributeId, editedFieldData.value)}
        >
          Reject Change
        </Button>
      )}
    </Box>
  );
}
