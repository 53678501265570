import React, { useState, useRef } from 'react';
import {
  Button,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { FaCloudUploadAlt } from 'react-icons/fa';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const DropZone = styled(Paper)(({ theme, isDragActive, isLoading }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(4),
  textAlign: 'center',
  cursor: isLoading ? 'not-allowed' : 'pointer',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: isDragActive
    ? theme.palette.primary.light
    : theme.palette.background.default,
  border: `2px dashed ${
    isDragActive ? theme.palette.primary.main : theme.palette.divider
  }`,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: isLoading ? 'inherit' : theme.palette.action.hover,
  },
  opacity: isLoading ? 0.7 : 1,
  position: 'relative',
}));

const FileInput = styled('input')({
  display: 'none',
});

const UploadIcon = styled(FaCloudUploadAlt)(({ theme }) => ({
  fontSize: 48,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  zIndex: 1,
}));

function DragDropUpload({
  processFile,
  acceptedFormats = ['csv'],
  loading = false,
}) {
  const fileInputRef = useRef(null);
  const [isDragActive, setIsDragActive] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    if (!loading) {
      setIsDragActive(true);
    }
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragActive(false);
    if (!loading) {
      const file = event.dataTransfer.files[0];
      if (file && isValidFileType(file)) {
        if (file.size <= MAX_FILE_SIZE) {
          processFile(file);
        } else {
          alert('File is too large. Please upload a file smaller than 5MB.');
        }
      } else {
        alert('Invalid file type. Please upload a supported file format.');
      }
    }
  };

  const handleFileChange = (event) => {
    if (!loading) {
      const file = event.target.files[0];
      if (file && isValidFileType(file)) {
        if (file.size <= MAX_FILE_SIZE) {
          processFile(file);
        } else {
          alert('File is too large. Please upload a file smaller than 5MB.');
        }
      } else {
        alert('Invalid file type. Please upload a supported file format.');
      }
    }
  };

  const isValidFileType = (file) => {
    return acceptedFormats.some((format) =>
      file.name.toLowerCase().endsWith(format.toLowerCase())
    );
  };

  const getAcceptString = () => {
    return acceptedFormats.map((format) => `.${format}`).join(',');
  };

  const formatList = acceptedFormats
    .map((format) => format.toUpperCase())
    .join(', ');

  return (
    <DropZone
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      isDragActive={isDragActive}
      isLoading={loading}
      elevation={3}
    >
      {loading && (
        <LoadingOverlay>
          <CircularProgress />
        </LoadingOverlay>
      )}
      <FileInput
        type="file"
        accept={getAcceptString()}
        ref={fileInputRef}
        onChange={handleFileChange}
        disabled={loading}
      />
      <UploadIcon />
      <Typography variant="h5" gutterBottom>
        {isDragActive ? 'Drop your file here' : 'Drag & Drop'}
      </Typography>
      <Typography variant="body1" gutterBottom>
        or
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => fileInputRef.current.click()}
        startIcon={<FaCloudUploadAlt />}
        disabled={loading}
      >
        Browse Files
      </Button>
      <Box mt={2}>
        <Typography variant="caption" color="textSecondary">
          Supported formats: {formatList}
        </Typography>
      </Box>
    </DropZone>
  );
}

export default DragDropUpload;
