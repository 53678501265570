import { Box, Typography } from '@mui/material';
import { FaMagic } from 'react-icons/fa';
const PDPTooltip = ({ attributeDefinition, modelExplanation }) => (
  <Box>
    {attributeDefinition && (
      <Box mb={1}>
        <Typography variant="subtitle2" fontWeight="bold">
          Attribute Definition:
        </Typography>
        <Typography variant="body2">{attributeDefinition}</Typography>
      </Box>
    )}
    {modelExplanation && (
      <Box>
        <Typography variant="subtitle2" fontWeight="bold">
          <FaMagic /> Explanation:
        </Typography>
        <Typography variant="body2">{modelExplanation}</Typography>
      </Box>
    )}
  </Box>
);

export default PDPTooltip;
