import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import { FaUpload } from 'react-icons/fa';
import { useAuthInfo } from '@propelauth/react';
import DragDropUpload from '../../components/DragDropUpload';
import axios from 'axios';
import Papa from 'papaparse';
import ProductFeedImport from './DataImport/ProductFeed';
import ScrapeOptions from './DataImport/ScrapeOptions';
import Shopify from './DataImport/Shopify';

const DataImport = ({
  sourceType,
  onImport,
  shopifyURL,
  setShopifyURL,
  setUploadedAssetId,
}) => {
  const { accessToken, orgHelper } = useAuthInfo();
  const [loading, setLoading] = useState(false);

  const processCSV = (file) => {
    if (file.type !== 'text/csv' && file.type !== 'application/json') {
      alert('Please upload a valid file type!');
      return;
    }
    const reader = new FileReader();

    reader.onload = async (e) => {
      const fileContent = e.target.result;
      const isDemo = orgHelper.getOrgs()[0]?.orgMetadata.demo;

      if (file.type === 'text/csv') {
        const { data, errors } = Papa.parse(fileContent, {
          header: true,
          delimiter: ',',
          skipEmptyLines: true, // Skip empty lines
        });
        if (errors.length > 0) {
          alert(
            'Error parsing CSV: ' +
              errors.map((error) => error.message).join(', ')
          );
          return;
        }
        const fileName = file.name.split('.').slice(0, -1).join('.');
        const limitedData = isDemo ? data.slice(0, 50) : data;
        onImport(limitedData, fileName);
      } else if (file.type === 'application/json') {
        try {
          const data = JSON.parse(fileContent);
          const fileName = file.name.split('.').slice(0, -1).join('.');
          const limitedData = isDemo ? data.slice(0, 50) : data;
          onImport(limitedData, fileName);
        } catch (error) {
          alert('Error parsing JSON: ' + error.message);
        }
      }
    };

    reader.readAsText(file);
  };

  const processPDF = async (file) => {
    if (file.type !== 'application/pdf') {
      alert('Please upload a PDF file.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/ai/parse-pdf`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.extracted_products.length === 0) {
        alert('Could not find any products in the uploaded file!');
        return;
      }
      const fileName = file.name.split('.').slice(0, -1).join('.');
      const data = response.data.extracted_products;
      setUploadedAssetId(response.data.linked_asset_id);
      onImport(data, fileName);
    } catch (error) {
      console.error('Error:', error);
      const errorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'Error parsing PDF';
      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const renderImportMethod = () => {
    switch (sourceType) {
      case 'FILE_UPLOAD':
        return (
          <DragDropUpload
            processFile={processCSV}
            acceptedFormats={['csv', 'json']}
          />
        );
      case 'UNSTRUCTURED':
        return (
          <DragDropUpload
            processFile={processPDF}
            acceptedFormats={['pdf']}
            loading={loading}
          />
        );
      case 'SCRAPE':
        return <ScrapeOptions onImport={onImport} />;
      case 'PRODUCT_FEED':
        return <ProductFeedImport onImport={onImport} />;
      case 'SHOPIFY':
        return (
          <Shopify
            onImport={onImport}
            shopifyURL={shopifyURL}
            setShopifyURL={setShopifyURL}
          />
        );
      default:
        return <Typography>Coming soon!</Typography>;
    }
  };

  return <Box>{renderImportMethod()}</Box>;
};

export default DataImport;
