import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { FaUpload, FaSyncAlt } from 'react-icons/fa';

const SourceDetails = ({
  sourceName,
  setSourceName,
  schedule,
  setSchedule,
  deduplicationField,
  setDeduplicationField,
  sourceType,
  onSubmit,
  dataMap,
}) => {
  const [removeDuplicates, setRemoveDuplicates] = useState(
    sourceType === 'SHOPIFY' ? true : false
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };

  const deduplicationOptions = useMemo(() => {
    const desiredFields = ["SKU", "URL", "Name", "ShopifyID"];
    return Object.entries(dataMap)
      .filter(([key, value]) => desiredFields.includes(value))
      .map(([key, value]) => value);
  }, [dataMap]);

  const handleRemoveDuplicatesChange = (event) =>
    setRemoveDuplicates(event.target.checked);
  const handleDeduplicationFieldChange = (event) =>
    setDeduplicationField(event.target.value);

  const handleSourceNameChange = (event) => {
    setSourceName(event.target.value);
  };

  const handleScheduleChange = (event) => {
    setSchedule(event.target.value);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Box>
          <InputLabel id="source-name-label">Source Name</InputLabel>
          <TextField
            value={sourceName}
            onChange={handleSourceNameChange}
            variant="outlined"
          />
        </Box>
        {(sourceType === 'SHOPIFY' || sourceType === 'SCRAPE') && (
          <Box>
            <InputLabel id="schedule-label">Schedule</InputLabel>
            <Select
              labelId="schedule-label"
              value={schedule}
              onChange={handleScheduleChange}
              label="Schedule"
              style={{ height: '56px' }} // Match TextField height
            >
              <MenuItem value="MANUAL">One-Off</MenuItem>
              <MenuItem value="HOURLY" disabled>
                Hourly
              </MenuItem>
              <MenuItem value="DAILY" disabled>
                Daily
              </MenuItem>
            </Select>
          </Box>
        )}
      </Box>

      <Box mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={removeDuplicates}
              onChange={handleRemoveDuplicatesChange}
              color="primary"
            />
          }
          label="Remove duplicates?"
          disabled={sourceType === 'SHOPIFY'}
        />
      </Box>

      {removeDuplicates && (
        <Box mb={2}>
          <InputLabel id="deduplication-field-label">
            Deduplication Field
          </InputLabel>
          <Select
            labelId="deduplication-field-label"
            value={deduplicationField}
            onChange={handleDeduplicationFieldChange}
            label="Deduplication Field"
            style={{ height: '56px', minWidht: '100px' }}
            disabled={sourceType === 'SHOPIFY'}
          >
            <MenuItem key="" value="">
              <em>None</em>
            </MenuItem>
            {deduplicationOptions.map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        startIcon={<FaUpload style={{ marginRight: '6px' }} />}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Create Source'}
      </Button>
    </Box>
  );
};

export default SourceDetails;
