import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ManageProducts from './ManageProducts';
import ProductDetails from './ProductDetails/ProductDetails';

function Products() {
  return (
    <div>
      <Routes>
        <Route index element={<ManageProducts />} />
        <Route path=":productId" element={<ProductDetails />} />
      </Routes>
    </div>
  );
}

export default Products;
