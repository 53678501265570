import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  Box,
  Button,
  IconButton,
  Typography,
  Chip,
} from '@mui/material';
import axios from 'axios';
import { useAuthInfo } from '@propelauth/react';
import SchemaContext from '../SchemaContext';
import { FaArrowLeft } from 'react-icons/fa';

export default function CreateAttribute() {
  const { schema, setSchema } = useContext(SchemaContext);
  const [attributeName, setAttributeName] = useState('');
  const [attributeType, setAttributeType] = useState('');
  const [allowedValues, setAllowedValues] = useState([]);
  const [context, setContext] = useState('');
  const [newAllowedValue, setNewAllowedValue] = useState('');
  const { accessToken, orgHelper } = useAuthInfo();
  const navigate = useNavigate();

  const handleTypeChange = (event) => {
    setAttributeType(event.target.value);
  };

  const toTitleCase = (str) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleAddAllowedValue = () => {
    if (
      newAllowedValue.trim() !== '' &&
      !allowedValues.includes(newAllowedValue)
    ) {
      const transformedValue = toTitleCase(newAllowedValue.trim());
      setAllowedValues([...allowedValues, transformedValue.trim()]);
      setNewAllowedValue('');
    }
  };

  const handleDeleteAllowedValue = (value) => {
    setAllowedValues(allowedValues.filter((val) => val !== value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/org/${
          orgHelper.getOrgs()[0].orgId
        }/catalog/create`,
        {
          attributeName,
          attributeType,
          allowedValues,
          context,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      setSchema({
        ...schema,
        [response.data.id]: {
          name: attributeName,
          enrichType: attributeType,
          allowedValues: allowedValues,
          definition: context,
        },
      });
      navigate('/attributes');
    } catch (error) {
      console.error('Error creating attribute:', error);
    }
  };

  return (
    <Box>
      <Box mb={3} display="flex" alignItems="center">
        <IconButton
          onClick={() => navigate('/attributes')}
          aria-label="go back"
          size="large"
        >
          <FaArrowLeft />
        </IconButton>
        <Typography variant="h4"> Create Attribute </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <Typography variant="h6" mt={4}>
            Attribute Name
          </Typography>
          <TextField
            label="Name"
            variant="outlined"
            value={attributeName}
            onChange={(e) => setAttributeName(e.target.value)}
            // fullWidth
            margin="normal"
          />
        </Box>
        <Box mb={3}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <Typography variant="h6">Attribute Type</Typography>
            <Select
              value={attributeType}
              onChange={handleTypeChange}
              label="Attribute Type"
            >
              <MenuItem value="GENERATE">Generate</MenuItem>
              <MenuItem value="CLASSIFY">Classify</MenuItem>
              <MenuItem value="EXTRACT">Extract</MenuItem>
              <MenuItem value="NONE">N/A</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mb={3}>
          <Typography variant="h6" mt={4}>
            Define Attribute
          </Typography>
          <TextField
            label="Definition"
            variant="outlined"
            value={context}
            onChange={(e) => setContext(e.target.value)}
            fullWidth
            margin="normal"
          />
        </Box>
        <Box mb={3}>
          {(attributeType === 'CLASSIFY' || attributeType === 'GENERATE') && (
            <FormControl fullWidth margin="normal">
              <Typography variant="h6">
                {attributeType === 'CLASSIFY'
                  ? 'Allowed Values'
                  : 'Suggested Values'}
              </Typography>
              <Box display="flex" alignItems="center">
                <TextField
                  value={newAllowedValue}
                  onChange={(e) => setNewAllowedValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddAllowedValue();
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  placeholder="Add allowed values"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddAllowedValue}
                  style={{ marginLeft: '8px' }}
                  disabled={!newAllowedValue}
                >
                  Add
                </Button>
              </Box>
              <Box mt={3}>
                {allowedValues.map((value, index) => (
                  <Chip
                    key={index}
                    label={value}
                    onDelete={() => handleDeleteAllowedValue(value)}
                    style={{ marginRight: '5px', marginBottom: '5px' }}
                  />
                ))}
              </Box>
            </FormControl>
          )}
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!attributeName || !attributeType}
        >
          Create Attribute
        </Button>
      </form>
    </Box>
  );
}
